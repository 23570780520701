<template>
	<div class="footer-wrap">
		<div class="footer-text">
			<!-- <div class="footer-bottom">
				<div class="footer-bottomtitle">友情链接：</div>
				<a clas>畅想家科技</a>
			</div> -->
			<div class="footer-top">
				<div>版权所有：北京中数后天科技有限公司 © 2008-{{year}} ALL RIGHTS RESERVED <a href="https://beian.miit.gov.cn/">冀ICP备2022015642号</a></div>
				<div>转载内容版权归作者及来源网站所有，本站原创内容转载请注明来源，商务合作请致电：13903126240 | 0312-3051117   或 15933970970</div>
			</div>
			
		</div>
	</div>
</template>

<script>
	export default {
	  name: 'Footernew',
	  props: {
	    msg: String
	  },
	  data() {
		return {
			year: new Date().getFullYear()
		}
	  }
	}
</script>

<style scoped="scoped">
	a{
		color: #60606d;
		text-decoration: none;
	}
	
	.footer-wrap{
		width: 100%;
		height: 200px;
		background-color: #06060b;
		display: flex;
	}
	.footer-text{
		width: 75%;
		margin: 30px auto 0;
		color: #60606d;
		
	}
	.footer-top{
		text-align: center;
		margin-top: 50px;
	}
	.footer-top div{
		font-size: 14px;
	}
	.footer-bottom{
		text-align: left;
	}
	.footer-bottom a{
		font-size: 14px;
		line-height: 40px;
	}
	.footer-bottomtitle{
		font-size: 14px;
	}
	
	
	@media (min-width: 320px) and (max-width: 500px){
		.footer-wrap{
			width: 100%;
			height: 95px;
			background-color: #06060b;
			display: flex;
		}
		.footer-text{
			width: 90%;
			margin: 0px auto 0;
			color: #60606d;
			
		}
		.footer-top{
			text-align: left;
			margin-top: 15px;
		}
		.footer-top div{
			font-size: 12px;
			line-height: 17px;
		}
		.footer-bottom{
			text-align: left;
		}
		.footer-bottom a{
			font-size: 12px;
			line-height: 30px;
		}
		.footer-bottomtitle{
			font-size: 12px;
		}
	}
	
	/* pad端 */
	@media (min-width: 500px) and (max-width: 800px){
		.footer-wrap{
			width: 100%;
			height: 150px;
			background-color: #06060b;
			display: flex;
		}
		.footer-text{
			width: 90%;
			margin: 20px auto 0;
			color: #60606d;
			
		}
		.footer-top{
			text-align: left;
			margin-top: 20px;
		}
		.footer-top div{
			font-size: 16px;
		}
		.footer-bottom{
			text-align: left;
		}
		.footer-bottom a{
			font-size: 14px;
			line-height: 30px;
		}
		.footer-bottomtitle{
			font-size: 14px;
		}
	}
	
</style>
