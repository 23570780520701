import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import 'wowjs/css/libs/animate.css' //导入wow.js文件
import 'fullpage.js/dist/fullpage.css' //导入wow.js文件

// 引入视频插件，这里如果不引入，无法监听视频播放进度
import Video from 'video.js'
import 'video.js/dist/video-js.css'
Vue.prototype.$video = Video


// 引用fullpage 插件
import 'fullpage.js/vendors/scrolloverflow' // Optional. When using scrollOverflow:true
import VueFullPage from 'vue-fullpage.js'


import api from "./quote/http.js" 

import waterfall from 'vue-waterfall2'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import echarts from 'echarts'

import VueImageSwipe from 'vue-image-swipe'
import 'vue-image-swipe/dist/vue-image-swipe.css'

import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'

let options = {
  maxSpreadZoom: 2, // 预览图最大的倍数，默认2倍
  fullscreenEl: false, //是否显示右上角全屏按钮
  closeEl: true, //是否显示右上角关闭按钮
  tapToClose: true, //点击滑动区域应关闭图库
  shareEl: false, //是否显示分享按钮
  zoomEl: false, //是否显示放大缩小按钮
  counterEl: false, //是否显示左上角图片数量按钮
  arrowEl: true,  //是否显示左右箭头（pc浏览器模拟手机时）
  tapToToggleControls: true, //点击应切换控件的可见性
  clickToCloseNonZoomable: true //点击图片应关闭图库，仅当图像小于视口的大小时
}
Vue.use(preview, options)

Vue.use(preview)

Vue.prototype.$api = api.overallUrl

Vue.prototype.$axios = axios;

Vue.use(ElementUI)
Vue.use(VueFullPage)
Vue.use(waterfall)
Vue.use(VueImageSwipe)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
