<template>
    <div>
		<!-- 顶部导航栏 -->
		<Comment class="commentWrap" :gotopshow="false"/> 
		<!-- 置顶按钮 -->
		<div class="gotop" @click="gototop">
			<span class="iconfont icon-stack"></span>
		</div>
		
        <full-page :options="options">
            <div class="section">
               <!-- <div class="box1" :style="sliderStyle"> -->
			    <div class="box1">
					<!-- 顶部导航栏 -->
					<div class="shareheaderwrap">
						<Headernew class="shareheader"/> 
					</div>
					
                   <!-- 顶部banner -->
                   		<div class="bannertop">
                   			 <div class="swiper-container">
                   			    <div class="parallax-bg" data-swiper-parallax="-23%"></div>
                   			    <div class="swiper-wrapper">
                   			      <div class="swiper-slide swiper-slide1">
                   					<div class="VRbg" data-swiper-parallax="-100">
                  						<video class="bannervideo" 
										:autoplay="videopay" 
										loop="loop" 
										muted="muted" 
										src="https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/home/indexbanner.mp4" poster="https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/home/none-1.png"></video>
										<img :src="bannergif" class="bannergif"/>
									</div>
                   					<div class="VRbgzhe"></div>
									<div class="banner-word">
										<!-- ● -->
										<div class="title banner1title" data-swiper-parallax="-300">企业●地产 科技服务商</div>
										<div class="subtitle banner1subtitle" data-swiper-parallax="-200">视觉体验与智能交互技术一站式数字营销解决方案</div>
									</div>
									
									<!-- 静音和声音图标 -->
									<div class="bannervoice" @click="changevocie">
										<span class="iconfont icon-jingyin-05" v-if="vicoce == 1"></span>
										<span class="iconfont icon-shengyin" v-if="vicoce == 2"></span>
									</div>
                   			      </div>
                   			      <div class="swiper-slide swiper-slide2">
									<div class="VRbgzhe"></div>
									<div class="banner-word">
										<div class="title banner2title" data-swiper-parallax="-300">数字影片 | 3D漫游动画 | 科技展厅</div>
										<div class="subtitle banner2subtitle" data-swiper-parallax="-200">营销拓客小程序 | 智慧楼盘 | VR全景直播</div>
									</div>
									
									<!-- 隐藏掉其他页面的头图 -->
									<div style="display: none;">
										<img src="https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/imgs/quality-bg.jpg" />
										<img src="https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/imgs/mediabanner3.jpg" />
										<img src="https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/imgs/mediabanner.jpg" />
										<img src="https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/imgs/viplistbanner.jpg" />
										<img src="https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/imgs/concatus.jpg" />
									</div>
                   			      </div>
                   			    </div>
                   
                   			    <div class="swiper-pagination swiper-pagination-white"></div>
                   
                   			    <div class="swiper-button-prev swiper-button-white"></div>
                   			    <div class="swiper-button-next swiper-button-white"></div>
                   			  </div>
                   		</div>
                </div>
            </div>
            <div class="section">
                <div class="box2">
						<!-- 第二屏 -->
						<div class="fourvideo-wrap">
							<div class="fourvideo-top">
								<div>数字影片 + 智能交互 + 科技展厅</div>
								<div>用最有效的方式，做最精准的传达多位一体营销模式</div>
							</div>
							
							<div class="fourvideo-bottom">
								<div class="fourvideo-video1">
									<!-- <video class="fourvideo-playvideo1" 
									src="../assets/videos/video1.mp4" 
									loop="loop" 
									muted="muted" 
									x5-playsinline="" 
									playsinline="" webkit-playsinline=""
									poster="../assets/videosport/second-1.png"
									></video> -->
									<img :src="secondone" />
								</div>
								<!-- <div class="fourvideo-video2">
									<img :src="secondtwo" />
								</div> -->
								<div class="fourvideo-video3">
									<div class="fourvideo-video4">
										<!-- <video class="fourvideo-playvideo3" poster="../assets/videosport/second-3.png" src="../assets/videos/video3.mp4" muted="muted" loop="loop" x5-playsinline="" playsinline="" webkit-playsinline=""></video> -->
										<img :src="secondthree" />
									</div>
									<div class="fourvideo-video5">
										<!-- <video class="fourvideo-playvideo4" poster="../assets/videosport/second-4.png" src="../assets/videos/video4.mp4" muted="muted" loop="loop" x5-playsinline="" playsinline="" webkit-playsinline=""></video> -->
										<img :src="secondfour" />
									</div>
								</div>
							</div>
						</div>
                </div>
            </div>
			<div class="section">
				<div class="box2">
					<div class="newthirteen-wrap">
						<div class="newthirteen-bgimg">
							<img id="newthreeimgone" src="../assets/img/rotate1.png" rel="200,100,300,-50"/>
							<img id="newthreeimgtwo" src="../assets/img/rotate1.png" rel="-280,100,-280,200"/>
						</div>
						
						<div class="newthirteen-right">
							<div class="newthirteen-rightvideo"></div>
						</div>
						
						<div class="newthirteen-left">
							<div class="newthirteen-title">营销拓客程序</div>
							<div class="newthirteen-text">助力企业自身的业务拓展及数据抓取</div>
						</div>
						
					</div>
				</div>
			</div>
            <div class="section">
                <div class="box3">
					<!-- 第四屏 -->
					<div class="thirteen-wrap">
						<div class="thirteen-bgimg">
							<img id="threeimgone" src="../assets/img/rotate1.png" rel="-400,-100,-200,30" />
							<img id="threeimgtwo" src="../assets/img/rotate1.png" rel="300,30,200,30" />
						</div>
						
						<div class="thirteen-left">
							<div class="thirteen-title">智慧展厅解决方案</div>
							<div class="thirteen-text">科技赋能企业，用最有效的方式做最贴切的传达</div>
						</div>
						
						<div class="thirteen-right">
							<div class="thirteen-rightvideo">
								<!-- <video class="thirteen-playvideo5" poster="../assets/videosport/zhen3.png" muted="muted" loop="loop" src="../assets/img/thirteenvideo.mp4" x5-playsinline="" playsinline="" webkit-playsinline=""></video> -->
								<img class="thirteen-playvideo5" :src="threeone" />
							</div>
							
							<img class="thirteen-img1" src="../assets/img/thirteenimg1.png" />
							<img class="thirteen-img2" src="../assets/img/thirteenimg2.png" />
							<img class="thirteen-img3" src="../assets/img/thirteenimg3.png" />
							<img class="thirteen-img4" src="../assets/img/thirteenimg4.png" />
						</div>
					</div>
                </div>
            </div>
			<div class="section">
			    <div class="box4">
						<!-- 第五屏 -->
						<div class="four-wrap">
							<div class="four-bgimg">
								<img id="fourimgone" src="../assets/img/rotate1.png" rel="-200,30,-600,-350"/>
								<img id="fourimgtwo" src="../assets/img/rotate1.png" rel="200,30,-180,-350"/>
							</div>
							
							<div class="four-left">
								<div class="four-title">后天科技助力企业数字化转型</div>
								<div class="four-text">我们潜心诠释数据的力量，希望企业每一次的选择都值得被铭记</div>
							</div>
							
							<div class="four-right">
								<div class="four-rightscroll">
									<div class="four-rightlist1">
										<div class="four-leftvideo">
											<img src="https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/home/third-1.png" />
										</div>
										<div class="four-leftvideo">
											<img src="https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/home/third-2.png" />
										</div>
										<div class="four-leftvideo">
											<img src="https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/home/third-3.png" />
										</div>
										<div class="four-leftvideo">
											<img src="https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/home/third-4.png" />
										</div>
									</div>
									<div class="four-rightlist2">
										<div class="four-leftvideo">
											<img src="https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/home/third-5.png" />
										</div>
										<div class="four-leftvideo">
											<img src="https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/home/third-6.png" />
										</div>
										<div class="four-leftvideo">
											<img src="https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/home/third-7.png" />
										</div>
										<div class="four-leftvideo">
											<img src="https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/home/third-8.png" />
										</div>
									</div>
									<div class="four-rightlist3">
										<div class="four-leftvideo">
											<img src="https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/home/third-9.png" />
										</div>
										<div class="four-leftvideo">
											<img src="https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/home/third-8.png" />
										</div>
										<div class="four-leftvideo">
											<img src="https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/home/third-4.png" />
										</div>
										<div class="four-leftvideo">
											<img src="https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/home/third-1.png" />
										</div>
									</div>
								</div>
							</div>
						</div>
			    </div>
			</div>
			<div class="section">
			    <div class="box5">
						<!-- 第六屏 企业新闻 -->
						<div class="news-wrap">
							<div class="news-top">
								<div class="news-toptitle">企业动态</div>
								<!-- <div class="news-more" @click="jumptoarticlelist">更多></div> -->
							</div>
							
							<div class="news-bottom">
								<div class="news-list" v-for="(item,index) in articles" @click="jumparticel(item.id)">
									<div class="news-img"><img :src="item.img" /></div>
									<div class="news-word">
										<div class="news-title">{{item.name}}</div>
										<div class="news-text">
											{{item.describe}}
										</div>
									</div>
								</div>
<!-- 								<div class="news-list">
									<div class="news-img"><img src="../assets/img/newslist2.png" /></div>
									<div class="news-word">
										<div class="news-title">后天美好奇妙夜：60位明星和131位抖音创作者同台，5250万人观看。</div>
										<div class="news-text">
											「2020年后天美好奇妙夜」结束了。这个一年一度的盛会，在被疫情影响的今年显得尤为不易，但更多的是美好和幸福的事情
										</div>
									</div>
								</div>
								<div class="news-list">
									<div class="news-img"><img src="../assets/img/newslist3.png" /></div>
									<div class="news-word">
										<div class="news-title">后天美好奇妙夜：60位明星和131位抖音创作者同台，5250万人观看。</div>
										<div class="news-text">
											「2020年后天美好奇妙夜」结束了。这个一年一度的盛会，在被疫情影响的今年显得尤为不易，但更多的是美好和幸福的事情
										</div>
									</div>
								</div> -->
							</div>
						</div>
						
						<Footernew/>
			    </div>
			</div>
        </full-page>
    </div>
</template>
 
<script>
	import Swiper from "swiper"
	import $ from "jquery"
	import Headernew from '@/components/Headernew.vue'
	import Footernew from '@/components/Footernew.vue'
	import Comment from '@/components/comment.vue'
	import {WOW} from 'wowjs'  //导入wow
	//在需要使用的路由里进行引用   也可以进行全局引用
	import wx from 'weixin-js-sdk'
	
	export default {
		name: '',
		components: {
		  Headernew,
		  Footernew,
		  Comment
		},
		data () {
			return {
				options: {
					licenseKey: "OPEN-SOURCE-GPLV3-LICENSE",
					afterLoad: this.afterLoad,
					onLeave:this.onLeave,
					scrollOverflow: true,
					scrollBar: false,
					menu: "#menu",
					easing:"",
					scrollingSpeed:300,
					// navigation: true,
					anchors: ['page1', 'page2', 'page3','page4','page5','page6'],
					sectionsColor: []
				  },
				  bannergif:"https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/yasuo/banner.gif",
				  secondone:"https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/yasuo/newtwo1.gif",
				  secondtwo:"https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/yasuo/two2.gif",
				  secondthree:"https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/yasuo/newtwo3.gif",
				  secondfour:"https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/yasuo/two4.gif",
				  threeone:"https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/yasuo/three.gif",
				  vicoce:1,//默认首页顶部banner视频1是静音
				  screenWidth: document.body.clientWidth,// 屏幕宽
				  // screenHeight: document.body.clientHeight,//屏幕高
				  // sliderStyle:{
				  //      height:document.body.clientHeight + "px"
				  // },
				  videopay:true,//控制自动播放标签，移动端全部暂停
				  articles:[],//接收底部文章列表的数组
				  
			}
		},
		watch:{
			// 检测屏幕宽度变化
			 screenWidth(val) {
				this.screenWidth = val;
				// if(this.screenWidth > 320 && this.screenWidth < 768){
				// 	this.navshow = false
				// }
			   }
			
		},
		created(){
			this.share()
		},
		mounted() {
			var that = this
			// 在项目加载完成之后初始化wow
			this.$nextTick(() => {
				let wow = new WOW({
					live:false
				})
				wow.init()
				
				// 页面加载完后，先计算一下整体的屏幕高度，为了下面调整box1做准备
				// that.screenHeight = document.body.clientHeight;
				// that.sliderStyle.height = document.body.clientHeight + "px"
			})
		    var mySwiper = new Swiper(".swiper-container", {
		      loop: false, // 循环模式选项
		      autoplay:false, // 自动播放 3秒
		      observer:true,
		      // 如果需要分页器
		      pagination: {
		        el: ".swiper-pagination"
		      },
			  navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			  },
		    });
			
			
			// 这里是用来获取屏幕宽度的
			window.onresize= () => {
			    return (() => {
			        window.screenWidth = document.body.clientWidth;
			        that.screenWidth = window.screenWidth;
			    })();
			}
			
			this.contorlpaly()
			this.getarticles()
		  },
		methods: {
			// 置顶点击
			gototop:function(){
				fullpage_api.moveTo('page1', 1);
			},
			
			// 跳转到文章列表页面
			jumptoarticlelist:function(){
				this.$router.replace({name: "articlelist"})
			},
			
			// 获取底部的文章列表
			getarticles:function(){
				let newurl = this.$api + "/get/Articles"
				this.$axios.get(newurl,{
					params:{
						page:1,
						limit:3
					}
				})
				.then((res) => {
					console.log(res)
					this.articles = res.data.result.data
				})
				.catch((err) => {
					console.log(err) //错误信息
				})
			},
			jumparticel:function(id){
				console.log(id)
				this.$router.replace({name: "articledetail", query:{id: id}})
			},
			
			// 控制移动端中，所有的视频取消自动播放
			contorlpaly:function(){
				var bannervideo = document.getElementsByClassName('bannervideo')[0]
				var palyvideo1 = document.getElementsByClassName('fourvideo-playvideo1')[0]
				var palyvideo2 = document.getElementsByClassName('fourvideo-playvideo2')[0]
				var palyvideo3 = document.getElementsByClassName('fourvideo-playvideo3')[0]
				var palyvideo4 = document.getElementsByClassName('fourvideo-playvideo4')[0]
				var palyvideo5 = document.getElementsByClassName('thirteen-playvideo5')[0]
				
					var that = this
					if(this.screenWidth > 320 && this.screenWidth < 768){
						that.videopay = false
					}else{
						that.videopay = true
					}
			},
			
			
		    afterLoad: function(origin, destination, direction) {
			var bannervideo = document.getElementsByClassName('bannervideo')[0]
			var palyvideo1 = document.getElementsByClassName('fourvideo-playvideo1')[0]
			var palyvideo2 = document.getElementsByClassName('fourvideo-playvideo2')[0]
			var palyvideo3 = document.getElementsByClassName('fourvideo-playvideo3')[0]
			var palyvideo4 = document.getElementsByClassName('fourvideo-playvideo4')[0]
			var palyvideo5 = document.getElementsByClassName('thirteen-playvideo5')[0]
				var that = this
				if(this.screenWidth > 320 && this.screenWidth < 768){
					bannervideo.pause()
				}else{
					if(destination.index == 0){
						bannervideo.play()
					}
				}
			// 当进入第一屏且是在微信中打开，就每隔一分钟计算一下第一屏的高度
			//防止因为底部的双向箭头导致的顶部导航栏错误
			//然后将这个高度赋值给box1
			// var time1
			// var ua = navigator.userAgent.toLowerCase();
			// if(destination.index == 0 && this.screenWidth < 800){
			// 	if(ua.match(/MicroMessenger/i) == 'micromessenger'){
			// 		time1 = setInterval(()=>{
			// 			window.screenHeight = document.body.clientHeight
			// 			if(that.screenHeight != window.screenHeight){
			// 				that.sliderStyle.height = window.screenHeight+"px"
			// 				that.screenHeight = window.screenHeight
			// 			}
			// 		},1000)
			// 	}
			// }	
			// 滚动到第二屏的时候，清掉定时器
			// if(destination.index == 1 && this.screenWidth < 800){
			// 	if(ua.match(/MicroMessenger/i) == 'micromessenger'){
			// 		clearInterval(time1)
			// 	}
			// }
			// 当进入第三屏
			if(destination.index == 2 && this.screenWidth > 800){
				$('#newthreeimgone').each(function(){
					var $rel = $(this).attr('rel');
					var $arr = $rel.split(',');
					$(this).animate({
						right: $arr[0] + 'px',
						top: $arr[1] + 'px'
					}, 500);
				});
				$('#newthreeimgtwo').each(function(){
					var $rel = $(this).attr('rel');
					var $arr = $rel.split(',');
					$(this).animate({
						right: $arr[0] + 'px',
						top: $arr[1] + 'px'
					}, 500);
				});
				
			}else{
				$('#newthreeimgone').each(function(){
					var $rel = $(this).attr('rel');
					var $arr = $rel.split(',');
					$(this).animate({
						right: $arr[2] + 'px',
						top: $arr[3] + 'px',
						easing:"linear"
					}, 500);
				});
				$('#newthreeimgtwo').each(function(){
					var $rel = $(this).attr('rel');
					var $arr = $rel.split(',');
					$(this).animate({
						right: $arr[2] + 'px',
						top: $arr[3] + 'px',
						easing:"linear"
					}, 500);
				});
			}
			
			// 当进入第四屏的时候
		      if(destination.index == 3 && this.screenWidth > 800){
					$('#threeimgone').each(function(){
						var $rel = $(this).attr('rel');
						var $arr = $rel.split(',');
						$(this).animate({
							left: $arr[2] + 'px',
							top: $arr[3] + 'px',
							easing:"linear"
						}, 500);
					});
					$('#threeimgtwo').each(function(){
						var $rel = $(this).attr('rel');
						var $arr = $rel.split(',');
						$(this).animate({
							left: $arr[2] + 'px',
							top: $arr[3] + 'px',
							easing:"linear"
						}, 1000);
					});
				}else{
					$('#threeimgone').each(function(){
						var $rel = $(this).attr('rel');
						var $arr = $rel.split(',');
						$(this).animate({
							left: $arr[0] + 'px',
							top: $arr[1] + 'px'
						}, 100);
					});
					$('#threeimgtwo').each(function(){
						var $rel = $(this).attr('rel');
						var $arr = $rel.split(',');
						$(this).animate({
							left: $arr[0] + 'px',
							top: $arr[1] + 'px'
						}, 100);
					});
				}

				// 当进入第五屏
				if(destination.index == 4 && this.screenWidth > 800){
					$('#fourimgone').each(function(){
						var $rel = $(this).attr('rel');
						var $arr = $rel.split(',');
						$(this).animate({
							left: $arr[2] + 'px',
							top: $arr[3] + 'px',
							easing:"linear"
						}, 500);
					});
					$('#fourimgtwo').each(function(){
						var $rel = $(this).attr('rel');
						var $arr = $rel.split(',');
						$(this).animate({
							left: $arr[2] + 'px',
							top: $arr[3] + 'px',
							easing:"linear"
						}, 500);
					});
					
				}else{
					$('#fourimgone').each(function(){
						var $rel = $(this).attr('rel');
						var $arr = $rel.split(',');
						$(this).animate({
							left: $arr[0] + 'px',
							top: $arr[1] + 'px'
						}, 100);
					});
					$('#fourimgtwo').each(function(){
						var $rel = $(this).attr('rel');
						var $arr = $rel.split(',');
						$(this).animate({
							left: $arr[0] + 'px',
							top: $arr[1] + 'px'
						}, 100);
					});
				}
				
				
		    },
			onLeave:function(lastIndex,nowIndex){

			},
			// 顶部banner的视频的静音和声音
			changevocie:function(){
				var bannervideo = document.getElementsByClassName('bannervideo')[0]
				if(this.vicoce == 1){
					this.vicoce = 2
					bannervideo.muted=false;
				}else{
					this.vicoce = 1
					bannervideo.muted=true;
				}
			},
			
			// 微信分享功能
			share:function(){
			        var that=this
					var shareUrl = window.location.href
					this.$axios.get('https://htkja.htkjbd.com/api/get/jssdk?path=' + encodeURIComponent(shareUrl))
					.then(function (res) {
					   wx.config({
						 debug: false,// true开启调试模式,pc端会console.log手机端会alert
						 appId: res.data.result.appId,// 必填，公众号的唯一标识，填自己的！
						 timestamp: res.data.result.timestamp, // 必填，生成签名的时间戳，刚才接口拿到的数据
						 nonceStr: res.data.result.nonceStr,   // 必填，生成签名的随机串
						 signature: res.data.result.signature, // 必填，签名，见附录1
						 jsApiList: [
						   'onMenuShareTimeline',
						   'onMenuShareAppMessage'
						 ]
					   })
					
					   //分享配置
					   wx.ready(function () {
						   //分享到朋友圈
						   wx.onMenuShareTimeline({
							 title: ' 后天科技',
							 link: 'http://xjaikj.htkjbd.com/',
							 imgUrl: 'https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/logo.png',
							 success: function () {
								  
							 },
							 cancel: function () {
							   // alert("取消分享");
							 }
						   });
						   //分享给朋友
						   wx.onMenuShareAppMessage({
							 title: '后天科技',
							 desc: '企业宣传片/专题片 3D漫游动画 数字科技展厅 营销拓客程序',
							 link: 'http://xjaikj.htkjbd.com/',
							 imgUrl: 'https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/logo.png',
							 success: function () {
								 // alert('分享成功')
							 },
							 cancel: function (err) {
							   // alert("取消分享");
							   alert(err)
							 }
						   });
					   })
					
					}).catch(function (err) {
															// alert(err)
											//        console.log(err)
					})  
					
			    },
			
		  }
	}
</script>
 
<style scoped="scoped">
 @import "../../node_modules/swiper/css/swiper.css";
 html { overflow-x: hidden; overflow-y: hidden; }
	 /* 评论组件 */
	.commentWrap{
		 position: fixed;
		 left: 0;
		 top: 0;
		 z-index: 100;
	 }
	 /* 置顶 */
	 .gotop{
	 	width: 50px;
	 	height: 70px;
	 	position: fixed;
	 	right: 18px;
	 	top: 690px;
	 	z-index: 110;
	 }
	 .gotop:hover{
	 	cursor: pointer;
	 }
	 .gotop span{
	 	color: #01AEEC;
	 	font-size: 45px;
	 }
   /* 顶部swiper */
   .bannertop{
         width: 100%;
         height: 100vh;
		 box-sizing: border-box;
		 overflow: hidden;
   }
    .swiper-container {
         width: 100vw;
         height: 100vh;
         background: #000;
   	     position: relative;
    }
    .swiper-slide {
		 width: 100vw !important;
		 height: 100vh;
		 font-size: 18px;
		 color: #fff;
		 -webkit-box-sizing: border-box;
		 box-sizing: border-box;
		 padding: 300px 160px 0 160px;
    }
   	.swiper-slide2{
   		background-image: url(https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/home/banner-033.jpg);
   		background-size: 100% 100%;
   	}
   	/* .swiper-slide3{
   		background-image: url(../assets/img/banner-144.jpg);
   		background-size: 100% 100%;
   	} */
   	.swiper-button-prev{
   		margin-left: 50px;
   	}
   	.swiper-button-next{
   		margin-right: 50px;
   	}
   	
   	
   	.VRbg{
   		position: absolute;
   		left: 0;
   		top: 0;
   		width: 100vw;
   		height: 100vh;
   		z-index: 2;
   	}
	.bannergif{
		display: none;
	}
   	.VRbgzhe{
   		position: absolute;
   		left: 0;
   		top: 0;
   		width: 100vw;
   		height: 100vh;
   		z-index: 3;
   		background-color: #000000;
   		opacity: 0.4;
   	}
   	.VRplay{
   		z-index: 3;
   		position: absolute;
   		left: 48%;
   		top: 43%;
   		width: 100px;
   		height: 100px;
   		border-radius: 50% 50%;
   		display: flex;
   		justify-content: center;
   		box-sizing: border-box;
   		align-items: center;
   		animation:mymove 1s;
   		-webkit-animation:mymove 2s infinite; /* Safari 和 Chrome */
   	}
   	@keyframes mymove {
   	    0% { opacity: 0.2;}
   	  100% { opacity: 1;}
   	}
   	.VRplay img{
   		width: 70px;
   		height: 70px;
   	}
	.bannervoice{
		z-index: 3;
		position: absolute;
		left: 30px;
		bottom:10px;
		width: 100px;
		height: 100px;
	}
	.bannervoice span{
		font-size:30px;
		color: #fff;
	}
       .parallax-bg {
         position: absolute;
         left: 0;
         top: 0;
         width: 130%;
         height: 100%;
         -webkit-background-size: cover;
         background-size: cover;
         background-position: center;
       }
	   .banner-word{
		   width: 60%;
		   text-align: center;
		   position: absolute;
		   left: 19%;
		   top: 40%;
		   z-index: 10;
	   }
       .swiper-slide .title {
         font-size: 61px;
         font-weight: bold;
		  z-index: 10;
/* 		  position: absolute;
		  left: 37%;
		  top: 35%; */
		  letter-spacing: 3px;
       }
   
       .swiper-slide .subtitle {
         font-size: 31px;
		  z-index: 3;
		  line-height: 70px;
/* 		  position: absolute;
		  left: 43%;
		  top: 45%; */
		  letter-spacing: 2px;
       }
   
       .swiper-slide .text {
         font-size: 14px;
         max-width: 400px;
         line-height: 1.3;
       }
   	
   	/* 第二屏 */
   	.fourvideo-wrap{
   		width: 100%;
   		height: 100vh;
   		box-sizing: border-box;
   	}
   	.fourvideo-top{
   		width: 85%;
   		height: 160px;
   		margin: 0 auto;
   		display: flex;
   		flex-direction: column;
   		justify-content: flex-end;
   	}
   	.fourvideo-top div{
   		text-align: left;
   	}
   	.fourvideo-top div:nth-of-type(1){
   		font-size: 45px;
   		font-weight: bold;
   		line-height: 60px;
   		color: #000000;
   	}
   	.fourvideo-top div:nth-of-type(2){
   		font-size: 28px;
   		color: #777e87;
   	}
   	.fourvideo-bottom{
   		width: 85%;
   		height: calc( 100vh - 160px );
   		margin: 0 auto;
   		display: flex;
   		justify-content: space-between;
   		align-items: center;
   	}
   	.fourvideo-video1{
   		width: 60.5%;
   		height: 66vh;
   		overflow: hidden;
   		border-radius: 20px;
   	}
 	/* .fourvideo-video1:hover{
 		width: 63%;
		height: 66vh;
		overflow: hidden;
 		transition: 0.4s;
 	} */
 	/* .fourvideo-video1:hover + .fourvideo-video2{
 		width: 22%;
 		transition: 0.4s;
 		overflow: hidden;
 	} */
 	/* .fourvideo-video1:hover ~ .fourvideo-video3{
 		width: 36%;
 		transition: 0.4s;
 		overflow: hidden;
 	} */
   	.fourvideo-video1 img{
		width: auto;
   		height: 100%;
 		border-radius: 20px;
   	}
   	/* .fourvideo-video2{
   		width: 22%;
   		height: 66vh;
   		overflow: hidden;
   		border-radius: 20px;
		background-color: #f8f6f8;
   	} */
/* 	.fourvideo-video2:hover{
 		width: 28%;
 		transition: 0.4s;
 	}
 	.fourvideo-video2:hover ~ .fourvideo-video1{
 		width: 33%;
 		transition: 0.4s;
 		overflow: hidden;
 	}
 	.fourvideo-video2:hover ~ .fourvideo-video3{
 		width: 33%;
 		transition: 0.4s;
 		overflow: hidden;
 	}
 	 */
   	.fourvideo-video2 img{
   		height: 66vh;
 		border-radius: 20px;
		margin-top: -2px;
   	}
   	.fourvideo-video3{
   		width: 38%;
   		height: 66vh;
   		display: flex;
   		flex-direction: column;
   		justify-content: space-between;
   	}
   	.fourvideo-video4{
   		width: 100%;
   		height: 54%;
   		border-radius: 20px;
   		overflow: hidden;
   	}
	.fourvideo-video4 img{
		width: 102%;
		height: auto;
		margin-left: -5px;
	}
 	.fourvideo-video5{
 		width: 100%;
 		height: 43%;
 		border-radius: 20px;
 		overflow: hidden;
 	}
	.fourvideo-video5 img{
		width: 100%;
		height: auto;
	}
 	/* .fourvideo-video4:hover{
 		height: 60%;
 		transition: 0.4s;
 	}
 	.fourvideo-video4:hover + .fourvideo-video5{
 		height: 37%;
 		transition: 0.4s;
 		overflow: hidden;
 	} */
   	/* 新增第三屏 */
	.newthirteen-wrap{
		width: 100%;
		height: 100vh;
		position: relative;
		display: flex;
		justify-content: space-between;
		overflow: hidden;
		box-sizing: border-box;
	}
	.newthirteen-bgimg{
		position: absolute;
		right: 0;
		top: 0;
		z-index: 1;
		background-color: yellow;
	}
	 
	.newthirteen-bgimg img:nth-of-type(1){
		width: 750px;
		height: 750px;
		position: absolute;
		/* right: 200px;
		top: 100px; */
		right: 300px;
		top: -50px;
		opacity: 0.2;
	} 
	.newthirteen-bgimg img:nth-of-type(2){
		width: 750px;
		height: 750px;
		position: absolute;
		/* right: -280px;
		top: 100px; */
		right: -280px;
		top: 200px; 
		opacity: 0.2;
	} 
	.newthirteen-left{
		width: 50%;
		height: 100%;
		position: absolute;
		right: 0;
		top: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		z-index: 4;
	}
	.newthirteen-title{
		font-size: 45px;
		font-weight: bold;
		line-height: 60px;
		color: #000000;
	}
	.newthirteen-text{
		font-size: 28px;
		color: #777e87;
	}
	.newthirteen-right{
		width: 100%;
		height: 100vh;
		z-index: 3;
		position: absolute;
		top: 0;
		left: 0;
	}
	.newthirteen-rightvideo{
		width: 100%;
		height: 100%;
		background-image: url(https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/secondyanpc.png);
		background-repeat: no-repeat;
		background-size: auto 100%;
	}

   	/* 第四屏 */
   	.thirteen-wrap{
   		width: 100%;
   		height: 100vh;
   		position: relative;
   		display: flex;
   		justify-content: space-between;
   		overflow: hidden;
   		box-sizing: border-box;
   	}
   	.thirteen-bgimg{
   		position: absolute;
   		left: 0;
   		top: 0;
   		z-index: 1;
   		background-color: yellow;
   	}
 
   	.thirteen-bgimg img:nth-of-type(1){
   		width: 750px;
   		height: 750px;
   		position: absolute;
   		left: -400px;
   		top: -100px;
   		opacity: 0.2;
   	} 
   	.thirteen-bgimg img:nth-of-type(2){
   		width: 750px;
   		height: 750px;
   		position: absolute;
   		left: 300px;
   		top: 30px;
   		opacity: 0.2;
   	} 
   	.thirteen-left{
   		width: 50%;
   		height: 100%;
   		display: flex;
   		flex-direction: column;
   		justify-content: center;
   		z-index: 4;
   	}
   	.thirteen-title{
   		font-size: 45px;
   		font-weight: bold;
   		line-height: 60px;
   		color: #000000;
   	}
   	.thirteen-text{
   		font-size: 28px;
   		color: #777e87;
   	}
   	.thirteen-right{
   		width: 40%;
   		height: 100%;
   		display: flex;
   		align-items: center;
   		z-index: 100;
   		position: relative;
   	}
   	.thirteen-rightvideo{
   		width: 420px;
   		/* height: auto; */
   		/* overflow: hidden; */
   		border-radius: 20px;
   	}
   	.thirteen-rightvideo img{
   		width: 100%;
		height: auto;
		border-radius: 20px;
   	}
   	.thirteen-img1{
   		width: 150px;
   		height: 150px;
   		position: absolute;
   		left: -75px;
   		top: 20px;
   	}
   	.thirteen-img2{
   		width: 180px;
   		height: 200px;
   		position: absolute;
   		right: 250px;
   		top: 120px;
   	}
   	.thirteen-img3{
   		width: 150px;
   		height: 150px;
   		position: absolute;
   		left: -100px;
   		bottom: 90px;
   	}
   	.thirteen-img4{
   		width: 150px;
   		height: 150px;
   		position: absolute;
   		right: 250px;
   		bottom: 0px;
   	}
   	
   	/* 第五屏 */
   	.four-wrap{
   		width: 100%;
   		height: 100vh;
   		position: relative;
   		overflow: hidden;
   		box-sizing: border-box;
   		display: flex;
   	}
   	.four-bgimg{
   		position: absolute;
   		left: 0;
   		top: 0;
   		z-index: 1;
   		/* transform: translate(-550px,-450px) scale(1.2,1.2);	 */
   	}
   	.four-bgimg img:nth-of-type(1){
   		width: 750px;
   		height: 750px;
   		position: absolute;
   		left: -200px;
   		top: 30px;
   		opacity: 0.2;
   	} 
   	.four-bgimg img:nth-of-type(2){
   		width: 750px;
   		height: 750px;
   		position: absolute;
   		left: 200px;
   		top: 30px;
   		opacity: 0.2;
   	} 
   	.four-left{
   		width: 40%;
   		height: 100%;
   		display: flex;
   		flex-direction: column;
   		z-index: 4;
   	}
   	.four-title{
   		font-size: 45px;
   		font-weight: bold;
   		line-height: 60px;
   		color: #000000;
   		margin-top: 150px;
   	}
   	.four-text{
   		font-size: 24px;
   		color: #777e87;
		margin-top: 15px;
   	}
   	
   	.four-right{
   		width: 60%;
   		height: 100%;
   		overflow: hidden;
   	}
   	.four-rightscroll{
   		width: 90%;
   		display: flex;
   		justify-content: space-between;
   	}
   	.four-rightlist1{
   		width: 32%;
   		padding-top: 200px;
   	}
   	.four-rightlist2{
   		width: 32%;
   		padding-top: 100px;
   	}
   	.four-rightlist3{
   		width: 32%;
   		padding-top: 150px;
   	}
   	/* 右侧流式加载视频 */
   	.four-leftvideo{
   		width: 100%;
   		margin-bottom: 20px;
   	}
   	.four-leftvideo img{
   		width: 100%;
   		border-radius: 20px;
   	}
   	
   	/* 第六屏 */
   	.news-wrap{
   		width: 100%;
   		height: calc( 100vh - 200px );
   	}
   	.news-top{
   		width: 85%;
   		height: 200px;
   		margin: 0 auto;
   		display: flex;
   		justify-content: space-between;
   		align-items: center;
   		z-index: 4;
   	}
   	.news-toptitle{
   		font-size: 40px;
   		font-weight: bold;
   		line-height: 60px;
   		color: #000000;
   	}
   	.news-more{
   		font-size: 16px;
   		color: #B4B2B2;
   	}
   	.news-more:hover{
   		cursor: pointer;
   	}
   	.news-bottom{
   		width: 85%;
   		margin: 0 auto;
   		display: flex;
   		/* justify-content: space-between; */
   	}
   	.news-list{
   		width: 33.333%;
		padding: 0 20px;
		box-sizing: border-box;
   	}
   	.news-img{
   		width: 100%;
   		height: 270px;
   		border-radius: 20px;
   		overflow: hidden;
   	}
   	.news-img img{
   		width: 100%;
   		height: auto;
   	}
   	.news-word{
   		width: 100%;
   	}
   	.news-title{
   		font-size: 24px;
   		font-weight: bold;
   		line-height: 60px;
   		color: #000000;
   		overflow: hidden;
   		  text-overflow: ellipsis;
   		  white-space:nowrap; 
   	}
   	.news-text{
   		line-height: 1.5vw;
   		font-size: .97vw;
   		color: #777e87;
   		text-align: justify;
   		letter-spacing: .06em;
   		  word-break: break-all;
   		  text-overflow: ellipsis;
   		  display: -webkit-box;
   		  -webkit-box-orient: vertical;
   		  -webkit-line-clamp: 2; /* 这里是超出几行省略 */
   		  overflow: hidden;
   	}
	
	/* 手机端自适应 */
	@media (min-width: 300px) and (max-width: 500px){
		/* 置顶 */
		.gotop{
			width: 50px;
			height: 70px;
			position: fixed;
			right: 7px;
			top: 570px;
			z-index: 110;
		}
		.gotop span{
			color: #01AEEC;
			font-size: 30px;
		}
		/* 第一屏的box1 */
		/* .section{
			position: relative;
		} */
		.box1{
			width: 100vw;
			height: 100vh;
			position: absolute;
			left: 0;
			top: 0;
			z-index: 900;
		}
		.shareheaderwrap{
			width: 100%;
			height: 60px;
			z-index: 2;
		}
		.shareheader{
			width: 100%;
			height: 60px;
		}
		/* 顶部swiper */
		.bannertop{
		      width: 100%;
		      height: 100vh;
			  box-sizing: border-box;
			  overflow: hidden;
			  position: absolute;
			  left: 0;
			  top: 0;
			  z-index: 1;
		}
		 .swiper-container {
		      width: 100vw;
		      height: 100vh;
		      background: #000;
			  position: relative;
		    }
		
		    .swiper-slide {
			  width: 100vw !important;
			  height: 100vh;
		      font-size: 12px;
		      color: #fff;
		      -webkit-box-sizing: border-box;
		      box-sizing: border-box;
		      padding: 0;
			  overflow: hidden;
		    }
			/* .swiper-slide2{
				background-image: url(../assets/img/banner-03.jpg);
				background-size: auto 100%;
			}
			.swiper-slide3{
				background-image: url(../assets/img/banner-14.jpg);
				background-size: auto 100%;
			} */
			.swiper-button-prev{
				margin-left: 15px;
				display: none;
			}
			.swiper-button-next{
				margin-right: 15px;
				display: none;
			}
			
			
			.VRbg{
				position: absolute;
				left: 0;
				top: 0;
				width: 100vw;
				height: 100vh;
				z-index: 2;
			}
			.bannervideo{
				display: none;
			}
			.bannergif{
				display: block;
				width: 100%;
				height: auto;
			}
			.VRbgzhe{
				position: absolute;
				left: 0;
				top: 0;
				width: 100vw;
				height: 100vh;
				z-index: 3;
				background-color: #000000;
				opacity: 0.4;
			}
			.VRplay{
				z-index: 3;
				position: absolute;
				left: 48%;
				top: 43%;
				width: 100px;
				height: 100px;
				border-radius: 50% 50%;
				display: flex;
				justify-content: center;
				box-sizing: border-box;
				align-items: center;
				animation:mymove 1s;
				-webkit-animation:mymove 2s infinite; /* Safari 和 Chrome */
			}
			@keyframes mymove {
			    0% { opacity: 0.2;}
			  100% { opacity: 1;}
			}
			.VRplay img{
				width: 70px;
				height: 70px;
			}
			.bannervoice{
				z-index: 3;
				position: absolute;
				left: 10px;
				bottom:10px;
				width: 30px;
				height: 30px;
				display: none;
			}
			.bannervoice span{
				font-size:24px;
				color: #fff;
			}
		    .parallax-bg {
		      position: absolute;
		      left: 0;
		      top: 0;
		      width: 130%;
		      height: 100%;
		      -webkit-background-size: cover;
		      background-size: cover;
		      background-position: center;
		    }
		
/* 		    .swiper-slide .title {
		      font-weight: bold;
			  z-index: 10;
			  position: absolute;
			  top: 35%;
			  letter-spacing: 3px;
			  width: 100% !important;
			  left: 0 !important;
			  font-size: 22px !important;
		    } */
		
/* 		    .swiper-slide .subtitle {
			  z-index: 3;
			  position: absolute;
			  left: 43%;
			  letter-spacing: 2px;
			  width: 100% !important;
			  top: 40% !important;
			  left: 0 !important;
			  font-size: 18px !important;
		    } */
			.banner-word{
			   width: 90%;
			   text-align: center;
			   position: absolute;
			   left: 5%;
			   top: 45%;
			   z-index: 10;
			}
			.title{
				width: 100% !important;
				font-size: 17px !important;
			}
			.subtitle{
				width: 100% !important;
				line-height: 20px !important;
				font-size: 14px !important;
				margin-top: 15px;
			}
			
			
			/* 第二屏 */
			.fourvideo-wrap{
				width: 100%;
				height: 100vh;
				box-sizing: border-box;
				overflow: hidden;
			}
			.fourvideo-top{
				width: 90%;
				height: auto;
				margin: 0 auto;
				display: block;
				padding-top: 20px;
			}
			.fourvideo-top div{
				text-align: left;
			}
			.fourvideo-top div:nth-of-type(1){
				font-size: 20px;
				font-weight: bold;
				line-height: 30px;
				color: #000000;
			}
			.fourvideo-top div:nth-of-type(2){
				font-size: 16px;
				color: #777e87;
				text-align: left;
			}
			.fourvideo-bottom{
				width: 90%;
				height: calc( 100vh - 90px );
				margin: 20px auto 0;
				display: flex;
				flex-wrap: wrap;
				align-content: flex-start;
			}
			.fourvideo-video1{
				width: 100%;
				height: 33%;
				overflow: hidden;
				border-radius: 5px;
			}
			.fourvideo-video1 img{
				width: 100%;
				height: auto;
		 		border-radius: 5px;
			}
			.fourvideo-video3{
				width: 100%;
				height: 63%;
				margin-top: 2%;
				display: flex;
				flex-direction: column;
				justify-content: center;
			}
			.fourvideo-video4{
				width: 100%;
				height: 51%;
				border-radius: 5px;
				overflow: hidden;
			}
			.fourvideo-video4 img{
				width: 102%;
				height: auto;
				margin-left: -5px;
			}
		 	.fourvideo-video5{
		 		width: 100%;
		 		height: 44%;
		 		border-radius: 5px;
		 		overflow: hidden;
				margin-top: 4%;
		 	}
			/* 新增第三屏 */
			.newthirteen-wrap{
				width: 100%;
				height: 100vh;
				position: relative;
				display: flex;
				justify-content: space-between;
				overflow: hidden;
				box-sizing: border-box;
			}
			.newthirteen-bgimg{
				position: absolute;
				right: 0;
				top: 0;
				z-index: 1;
				background-color: yellow;
			}
			 
			.newthirteen-bgimg img:nth-of-type(1){
				width: 600px;
				height: 600px;
				position: absolute;
				opacity: 0.2;
			} 
			.newthirteen-bgimg img:nth-of-type(2){
				width: 600px;
				height: 600px;
				position: absolute;
				opacity: 0.2;
			} 
			.newthirteen-left{
				width: 100%;
				height: auto;
				position: absolute;
				left: 0;
				top: 30px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				z-index: 4;
			}
			.newthirteen-title{
				font-size: 20px;
				font-weight: bold;
				line-height: 30px;
				color: #000000;
			}
			.newthirteen-text{
				font-size: 16px;
				color: #777e87;
			}
			.newthirteen-right{
				width: 100%;
				height: 100vh;
				z-index: 3;
				position: absolute;
				top: 0;
				left: 0;
			}
			.newthirteen-rightvideo{
				width: 100%;
				height: 100%;
				background-image: url(https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/secondyanyi.png);
				background-repeat: no-repeat;
				background-size: 100% auto;
			}
			/* 第四屏 */
			.thirteen-wrap{
				width: 100%;
				height: 100vh;
				margin: 0 auto;
				position: relative;
				display: block;
				overflow: hidden;
				box-sizing: border-box;
			}
			.thirteen-bgimg{
				position: absolute;
				left: 0;
				top: 0;
				z-index: 1;
			}
		 
			.thirteen-bgimg img:nth-of-type(1){
				width: 400px;
				height: 400px;
				position: absolute;
				left: -300px;
				top: -100px;
				opacity: 0.2;
			} 
			.thirteen-bgimg img:nth-of-type(2){
				width: 400px;
				height: 400px;
				position: absolute;
				left: 200px;
				top: 30px;
				opacity: 0.2;
			} 
			.thirteen-left{
				width: 90%;
				height: 90px;
				margin: 0 auto;
				padding-top: 20px;
				box-sizing: border-box;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: flex-start;
				z-index: 4;
			}
			.thirteen-title{
				font-size: 20px;
				font-weight: bold;
				line-height: 30px;
				color: #000000;
				text-align: left;
			}
			.thirteen-text{
				font-size: 16px;
				color: #777e87;
			}
			.thirteen-right{
				width: 90%;
				height: 100%;
				margin: 25px auto 0;
				display: block;
				z-index: 100;
				position: relative;
			}
			.thirteen-rightvideo{
				width: 86%;
				height: 81%;
				margin: 0 auto;
				overflow: hidden;
				border-radius: 10px;
				display: flex;
				align-items: center;
			}
			.thirteen-rightvideo img{
				width: 100%;
			}
			.thirteen-img1{
				width: 70px;
				height: 70px;
				position: absolute;
				left: 0px;
				top: -20px;
			}
			.thirteen-img2{
				width: 80px;
				height: 80px;
				position: absolute;
				right: 0px;
				top: 10px;
			}
			.thirteen-img3{
				width: 70px;
				height: 70px;
				position: absolute;
				left: -10px;
				bottom: 180px;
			}
			.thirteen-img4{
				width: 70px;
				height: 70px;
				position: absolute;
				right: 0px;
				bottom: 150px;
			}
			
			/* 第五屏 */
			.four-wrap{
				width: 100%;
				height: 100vh;
				position: relative;
				overflow: hidden;
				box-sizing: border-box;
				display: block;
			}
			.four-bgimg{
				position: absolute;
				left: 0;
				top: 0;
				z-index: 1;
				/* transform: translate(-550px,-450px) scale(1.2,1.2);	 */
			}
			.four-bgimg img:nth-of-type(1){
				width: 500px;
				height: 500px;
				position: absolute;
				left: -200px;
				top: 30px;
				opacity: 0.2;
			} 
			.four-bgimg img:nth-of-type(2){
				width: 500px;
				height: 500px;
				position: absolute;
				left: 200px;
				top: 30px;
				opacity: 0.2;
			} 
			.four-left{
				width: 90%;
				height: auto;
				margin: 0 auto;
				z-index: 4;
				padding-top: 20px;
				text-align: left;
			}
			.four-title{
				width: 100%;
				font-size: 20px;
				font-weight: bold;
				line-height: 30px;
				color: #000000;
				margin-top: 0px;
				text-align: left;
			}
			.four-text{
				width: 100%;
				text-align: left;
				font-size: 16px;
				color: #777e87;
				margin-top: 4px;
			}
			
			.four-right{
				width: 90%;
				height: calc( 100vh - 70px);
				margin: 20px auto 0;
				overflow: hidden;
			}
			.four-rightscroll{
				width: 100%;
				display: flex;
				justify-content: space-between;
			}
			.four-rightlist1{
				width: 32%;
				padding-top: 50px;
			}
			.four-rightlist2{
				width: 32%;
				padding-top: 20px;
			}
			.four-rightlist3{
				width: 32%;
				padding-top: 60px;
			}
			/* 右侧流式加载视频 */
			.four-leftvideo{
				width: 100%;
				margin-bottom: 10px;
			}
			.four-leftvideo img{
				width: 100%;
				border-radius: 20px;
			}
			
			/* 第六屏 */
			.news-wrap{
				width: 100%;
				height: calc( 100vh - 105px );
				margin: 0 auto 15px;
				overflow: hidden;
			}
			.news-top{
				width: 90%;
				height: auto;
				margin: 0 auto;
				display: flex;
				justify-content: space-between;
				align-items: center;
				z-index: 4;
				padding-top: 15px;
			}
			.news-toptitle{
				font-size: 20px;
				font-weight: bold;
				line-height: 30px;
				color: #000000;
			}
			.news-more{
				font-size: 14px;
				color: #B4B2B2;
			}
			.news-bottom{
				width: 90%;
				height: calc( 100% - 70px);
				margin: 15px auto 0;
				display: flex;
				flex-direction: row;
				/* justify-content: space-between; */
				align-content: flex-start;
				flex-wrap: wrap;
			}
			.news-list{
				width: 100%;
				height: 50%;
				padding: 0 5px;
				margin-bottom: 13px;
			}
			.news-img{
				width: 100%;
				height: 66%;
				border-radius: 5px;
				overflow: hidden;
			}
			.news-img img{
				width: 100%;
				height: auto;
				vertical-align: bottom;
			}
			.news-word{
				width: 100%;
				height: 26%;
				overflow: hidden;
				margin-top: 15px;
			}
			.news-title{
				font-size: 16px;
				font-weight: bold;
				line-height: 16px;
				color: #000000;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space:nowrap; 
			}
			.news-text{
				line-height: 18px;
				font-size: 14px;
				margin-top: 8px;
				color: #777e87;
				text-align: justify;
				letter-spacing: 0px;
				  word-break: break-all;
				  text-overflow: ellipsis;
				  display: -webkit-box;
				  -webkit-box-orient: vertical;
				  -webkit-line-clamp: 2; /* 这里是超出几行省略 */
				  overflow: hidden;
			}
	}
	
	
	/* pad端 */
		@media (min-width: 500px) and (max-width: 800px){
			.box1{
				width: 100vw;
				height: 100vh;
				position: absolute;
				left: 0;
				top: 0;
				z-index: 900;
			}
			.shareheaderwrap{
				width: 100%;
				height: 80px;
				z-index: 2;
			}
			.shareheader{
				width: 100%;
				height: 80px;
			}
			/* 顶部swiper */
			.bannertop{
			      width: 100%;
			      height: 100vh;
				  box-sizing: border-box;
				  overflow: hidden;
				  position: absolute;
				  left: 0;
				  top: 0;
				  z-index: 1;
			}
			 .swiper-container {
			      width: 100vw;
			      height: 100vh;
			      background: #000;
				  position: relative;
			    }
			
			    .swiper-slide {
				  width: 100vw !important;
				  height: 100vh;
			      font-size: 12px;
			      color: #fff;
			      -webkit-box-sizing: border-box;
			      box-sizing: border-box;
			      padding: 0;
				  overflow: hidden;
			    }
				/* .swiper-slide2{
					background-image: url(../assets/img/banner-03.jpg);
					background-size: auto 100%;
				}
				.swiper-slide3{
					background-image: url(../assets/img/banner-14.jpg);
					background-size: auto 100%;
				} */
				.swiper-button-prev{
					margin-left: 15px;
					display: none;
				}
				.swiper-button-next{
					margin-right: 15px;
					display: none;
				}
				
				
				.VRbg{
					position: absolute;
					left: 0;
					top: 0;
					width: 100vw;
					height: 100vh;
					z-index: 2;
				}
				.bannervideo{
					display: none;
				}
				.bannergif{
					display: block;
					width: 100%;
					height: auto;
				}
				.VRbgzhe{
					position: absolute;
					left: 0;
					top: 0;
					width: 100vw;
					height: 100vh;
					z-index: 3;
					background-color: #000000;
					opacity: 0.4;
				}
				.VRplay{
					z-index: 3;
					position: absolute;
					left: 48%;
					top: 43%;
					width: 100px;
					height: 100px;
					border-radius: 50% 50%;
					display: flex;
					justify-content: center;
					box-sizing: border-box;
					align-items: center;
					animation:mymove 1s;
					-webkit-animation:mymove 2s infinite; /* Safari 和 Chrome */
				}
				@keyframes mymove {
				    0% { opacity: 0.2;}
				  100% { opacity: 1;}
				}
				.VRplay img{
					width: 70px;
					height: 70px;
				}
				.bannervoice{
					z-index: 3;
					position: absolute;
					left: 10px;
					bottom:10px;
					width: 30px;
					height: 30px;
					display: none;
				}
				.bannervoice span{
					font-size:24px;
					color: #fff;
				}
			    .parallax-bg {
			      position: absolute;
			      left: 0;
			      top: 0;
			      width: 130%;
			      height: 100%;
			      -webkit-background-size: cover;
			      background-size: cover;
			      background-position: center;
			    }
			
	/* 		    .swiper-slide .title {
			      font-weight: bold;
				  z-index: 10;
				  position: absolute;
				  top: 35%;
				  letter-spacing: 3px;
				  width: 100% !important;
				  left: 0 !important;
				  font-size: 22px !important;
			    } */
			
	/* 		    .swiper-slide .subtitle {
				  z-index: 3;
				  position: absolute;
				  left: 43%;
				  letter-spacing: 2px;
				  width: 100% !important;
				  top: 40% !important;
				  left: 0 !important;
				  font-size: 18px !important;
			    } */
				.banner-word{
				   width: 79%;
				   text-align: center;
				   position: absolute;
				   left: 10%;
				   top: 55%;
				   z-index: 10;
				}
				.title{
					width: 100% !important;
					font-size: 22px !important;
				}
				.subtitle{
					width: 100% !important;
					line-height: 20px !important;
					font-size: 18px !important;
					margin-top: 15px;
				}
				
				
				/* 第二屏 */
				.fourvideo-wrap{
					width: 100%;
					height: 100vh;
					box-sizing: border-box;
					overflow: hidden;
				}
				.fourvideo-top{
					width: 90%;
					height: 80px;
					margin: 0 auto;
					display: flex;
					flex-direction: column;
					justify-content: flex-end;
				}
				.fourvideo-top div{
					text-align: left;
				}
				.fourvideo-top div:nth-of-type(1){
					font-size: 20px;
					font-weight: bold;
					line-height: 30px;
					color: #000000;
				}
				.fourvideo-top div:nth-of-type(2){
					font-size: 16px;
					color: #777e87;
				}
				.fourvideo-bottom{
					width: 90%;
					height: calc( 100vh - 120px );
					margin: 20px auto 0;
					padding-bottom: 20px;
					display: flex;
					flex-wrap: wrap;
					align-content: flex-start;
				}
				.fourvideo-video1{
					width: 100%;
					height: 38%;
					overflow: hidden;
					border-radius: 5px;
				}
				.fourvideo-video1 img{
					width: 100%;
					height: auto;
			 		border-radius: 5px;
				}
				.fourvideo-video3{
					width: 100%;
					height: 61%;
					margin-top: 2%;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
				}
				.fourvideo-video4{
					width: 100%;
					height: 52%;
					border-radius: 5px;
					overflow: hidden;
				}
				.fourvideo-video4 img{
					width: 102%;
					height: auto;
					margin-left: -5px;
				}
			 	.fourvideo-video5{
			 		width: 100%;
			 		height: 45%;
			 		border-radius: 5px;
			 		overflow: hidden;
			 	}
				/* 新增第三屏 */
				.newthirteen-wrap{
					width: 100%;
					height: 100vh;
					position: relative;
					display: flex;
					justify-content: space-between;
					overflow: hidden;
					box-sizing: border-box;
				}
				.newthirteen-bgimg{
					position: absolute;
					right: 0;
					top: 0;
					z-index: 1;
					background-color: yellow;
				}
				 
				.newthirteen-bgimg img:nth-of-type(1){
					width: 700px;
					height: 700px;
					position: absolute;
					opacity: 0.2;
				} 
				.newthirteen-bgimg img:nth-of-type(2){
					width: 700px;
					height: 700px;
					position: absolute;
					opacity: 0.2;
				} 
				.newthirteen-left{
					width: 100%;
					height: auto;
					position: absolute;
					left: 0;
					top: 30px;
					display: flex;
					flex-direction: column;
					justify-content: center;
					z-index: 4;
				}
				.newthirteen-title{
					font-size: 20px;
					font-weight: bold;
					line-height: 30px;
					color: #000000;
				}
				.newthirteen-text{
					font-size: 16px;
					color: #777e87;
				}
				.newthirteen-right{
					width: 100%;
					height: 100vh;
					z-index: 3;
					position: absolute;
					top: 0;
					left: 0;
				}
				.newthirteen-rightvideo{
					width: 100%;
					height: 100%;
					background-image: url(https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/secondyanyi.png);
					background-repeat: no-repeat;
					background-size: 100% auto;
				}
				
				/* 第四屏 */
				.thirteen-wrap{
					width: 100%;
					height: 100vh;
					margin: 0 auto;
					position: relative;
					display: block;
					overflow: hidden;
					box-sizing: border-box;
				}
				.thirteen-bgimg{
					position: absolute;
					left: 0;
					top: 0;
					z-index: 1;
				}
			 
				.thirteen-bgimg img:nth-of-type(1){
					width: 700px;
					height: 700px;
					position: absolute;
					left: -300px;
					top: -100px;
					opacity: 0.2;
				} 
				.thirteen-bgimg img:nth-of-type(2){
					width: 700px;
					height: 700px;
					position: absolute;
					left: 200px;
					top: 30px;
					opacity: 0.2;
				} 
				.thirteen-left{
					width: 90%;
					height: 90px;
					margin: 0 auto;
					padding-top: 20px;
					box-sizing: border-box;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: flex-start;
					z-index: 4;
				}
				.thirteen-title{
					font-size: 20px;
					font-weight: bold;
					line-height: 30px;
					color: #000000;
				}
				.thirteen-text{
					font-size: 16px;
					color: #777e87;
				}
				.thirteen-right{
					width: 90%;
					height: 100%;
					margin: 60px auto 0;
					display: block;
					z-index: 100;
					position: relative;
				}
				.thirteen-rightvideo{
					width: 60%;
					height: 75%;
					margin: 0 auto;
					overflow: hidden;
					border-radius: 10px;
					display: flex;
					align-items: center;
				}
				.thirteen-rightvideo img{
					width: 100%;
				}
				.thirteen-img1{
					width: 100px;
					height: 100px;
					position: absolute;
					left: 80px;
					top: -30px;
				}
				.thirteen-img2{
					width: 130px;
					height: 130px;
					position: absolute;
					right: 70px;
					top: 30px;
				}
				.thirteen-img3{
					width: 110px;
					height: 110px;
					position: absolute;
					left: 80px;
					bottom: 230px;
				}
				.thirteen-img4{
					width: 120px;
					height: 120px;
					position: absolute;
					right: 70px;
					bottom: 200px;
				}
				
				/* 第五屏 */
				.four-wrap{
					width: 100%;
					height: 100vh;
					position: relative;
					overflow: hidden;
					box-sizing: border-box;
					display: block;
				}
				.four-bgimg{
					position: absolute;
					left: 0;
					top: 0;
					z-index: 1;
					/* transform: translate(-550px,-450px) scale(1.2,1.2);	 */
				}
				.four-bgimg img:nth-of-type(1){
					width: 750px;
					height: 750px;
					position: absolute;
					left: -200px;
					top: 30px;
					opacity: 0.2;
				} 
				.four-bgimg img:nth-of-type(2){
					width: 750px;
					height: 750px;
					position: absolute;
					left: 200px;
					top: 30px;
					opacity: 0.2;
				} 
				.four-left{
					width: 90%;
					height: auto;
					margin: 0 auto;
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					z-index: 4;
					padding-top: 30px;
				}
				.four-title{
					font-size: 20px;
					font-weight: bold;
					line-height: 30px;
					color: #000000;
					margin-top: 0px;
				}
				.four-text{
					font-size: 18px;
					color: #777e87;
					margin-top: 0;
				}
				
				.four-right{
					width: 90%;
					height: calc( 100vh - 70px);
					margin: 20px auto 0;
					overflow: hidden;
				}
				.four-rightscroll{
					width: 100%;
					display: flex;
					justify-content: space-between;
				}
				.four-rightlist1{
					width: 32%;
					padding-top: 50px;
				}
				.four-rightlist2{
					width: 32%;
					padding-top: 20px;
				}
				.four-rightlist3{
					width: 32%;
					padding-top: 60px;
				}
				/* 右侧流式加载视频 */
				.four-leftvideo{
					width: 100%;
					margin-bottom: 10px;
				}
				.four-leftvideo img{
					width: 100%;
					border-radius: 20px;
				}
				
				/* 第六屏 */
				.news-wrap{
					width: 100%;
					margin: 0 auto;
					height: calc( 100vh - 150px );
					overflow: hidden;
				}
				.news-top{
					width: 90%;
					height: 70px;
					margin: 0 auto;
					display: flex;
					justify-content: space-between;
					align-items: center;
					z-index: 4;
				}
				.news-toptitle{
					font-size: 20px;
					font-weight: bold;
					line-height: 30px;
					color: #000000;
				}
				.news-more{
					font-size: 14px;
					color: #B4B2B2;
				}
				
				.news-bottom{
					width: 90%;
					height: calc( 100% - 70px);
					margin: 0 auto;
					display: flex;
					flex-direction: row;
					/* justify-content: space-between; */
					align-content: flex-start;
					flex-wrap: wrap;
				}
				.news-list{
					width: 100%;
					height: 43%;
					padding: 0 15px;
					margin-bottom: 60px;
				}
				.news-img{
					width: 100%;
					height: 80%;
					border-radius: 5px;
					overflow: hidden;
				}
				.news-img img{
					width: 100%;
					height: auto;
				}
				.news-word{
					width: 100%;
					margin-top: 15px;
				}
				.news-title{
					font-size: 19px;
					font-weight: bold;
					line-height: 30px;
					color: #000000;
					overflow: hidden;
					  text-overflow: ellipsis;
					  white-space:nowrap; 
				}
				.news-text{
					line-height: 27px;
					font-size: 17px;
					margin-top: 7px;
					color: #777e87;
					text-align: justify;
					letter-spacing: 0px;
					  word-break: break-all;
					  text-overflow: ellipsis;
					  display: -webkit-box;
					  -webkit-box-orient: vertical;
					  -webkit-line-clamp: 2; /* 这里是超出几行省略 */
					  overflow: hidden;
				}
		}
		
		
		/* 老板仓定制机的样式 */
		@media (min-width: 1000px) and (max-width: 1300px){
			/* 评论组件 */
				.commentWrap{
					 position: fixed;
					 left: 0;
					 top: 0;
					 z-index: 100;
				 }
				 /* 置顶 */
				 .gotop{
				 	width: 40px;
				 	height: 70px;
				 	position: fixed;
				 	right: 22px;
				 	/* top: 690px; */
					top: auto;
					bottom: 70px;
				 	z-index: 110;
				 }
				 .gotop:hover{
				 	cursor: pointer;
				 }
				 .gotop span{
				 	color: #01AEEC;
				 	font-size: 35px;
				 }
				 .box1,.section{
					 height: inherit;
				 }
			   /* 顶部swiper */
			   .bannertop{
			         width: 100%;
			         /* height: 100vh; */
					 height: inherit;
					 box-sizing: border-box;
					 overflow: hidden;
			   }
			    .swiper-container {
			         width: 100vw;
			         /* height: 100vh; */
					 height: inherit;
			         background: #000;
			   	     position: relative;
			    }
			    .swiper-slide {
					 width: 100vw !important;
					 /* height: 100vh; */
					 height: inherit;
					 font-size: 18px;
					 color: #fff;
					 -webkit-box-sizing: border-box;
					 box-sizing: border-box;
					 padding: 300px 160px 0 160px;
			    }
			   	.swiper-slide2{
			   		background-image: url(https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/home/banner-033.jpg);
			   		background-size: 100% 100%;
			   	}
			   	/* .swiper-slide3{
			   		background-image: url(../assets/img/banner-144.jpg);
			   		background-size: 100% 100%;
			   	} */
			   	.swiper-button-prev{
			   		margin-left: 50px;
			   	}
			   	.swiper-button-next{
			   		margin-right: 50px;
			   	}
			   	.bannervideo{
					width: 100%;
					height: auto;
				}
			   	
			   	.VRbg{
			   		position: absolute;
			   		left: 0;
			   		top: 0;
			   		width: 100vw;
			   		/* height: 100vh; */
					height: inherit;
			   		z-index: 2;
			   	}
				.bannergif{
					display: none;
				}
			   	.VRbgzhe{
			   		position: absolute;
			   		left: 0;
			   		top: 0;
			   		width: 100vw;
			   		/* height: 100vh; */
					height: inherit;
			   		z-index: 3;
			   		background-color: #000000;
			   		opacity: 0.4;
			   	}
			   	.VRplay{
			   		z-index: 3;
			   		position: absolute;
			   		left: 48%;
			   		top: 43%;
			   		width: 100px;
			   		height: 100px;
			   		border-radius: 50% 50%;
			   		display: flex;
			   		justify-content: center;
			   		box-sizing: border-box;
			   		align-items: center;
			   		animation:mymove 1s;
			   		-webkit-animation:mymove 2s infinite; /* Safari 和 Chrome */
			   	}
			   	@keyframes mymove {
			   	    0% { opacity: 0.2;}
			   	  100% { opacity: 1;}
			   	}
			   	.VRplay img{
			   		width: 70px;
			   		height: 70px;
			   	}
				.bannervoice{
					z-index: 3;
					position: absolute;
					left: 30px;
					bottom:10px;
					width: 100px;
					height: 100px;
				}
				.bannervoice span{
					font-size:30px;
					color: #fff;
				}
			       .parallax-bg {
			         position: absolute;
			         left: 0;
			         top: 0;
			         width: 130%;
			         height: 100%;
			         -webkit-background-size: cover;
			         background-size: cover;
			         background-position: center;
			       }
				   .banner-word{
					   width: 65%;
					   text-align: center;
					   position: absolute;
					   left: 19%;
					   top: 40%;
					   z-index: 10;
				   }
			       /* .swiper-slide .title {
			         font-size: 50px;
			         font-weight: bold;
					  z-index: 10;
					  letter-spacing: 3px;
			       } */
				   /* .swiper-slide .subtitle {
				     font-size: 22px;
					  z-index: 3;
					  line-height: 70px;
					  letter-spacing: 2px;
				   } */
				  .swiper-slide .banner1title{
					   font-size: 50px;
					   font-weight: bold;
					    z-index: 10;
					    letter-spacing: 3px;
				   }
				  .swiper-slide .banner1subtitle{
					   font-size: 22px;
					    z-index: 3;
					    line-height: 70px;
					    letter-spacing: 2px;
				   }
			      .swiper-slide .banner2title{
					   font-size: 40px;
					   font-weight: bold;
						z-index: 10;
						letter-spacing: 3px;
			       }
			      .swiper-slide .banner2subtitle{
					   font-size: 22px;
						z-index: 3;
						line-height: 70px;
						letter-spacing: 2px;
			       }
			   
			       .swiper-slide .text {
			         font-size: 14px;
			         max-width: 400px;
			         line-height: 1.3;
			       }
			   	
			   	/* 第二屏 */
					
				.box2{
					height: inherit;
					overflow: hidden;
				}
			   	.fourvideo-wrap{
			   		width: 100%;
			   		/* height: 100vh; */
					height: inherit;
			   		box-sizing: border-box;
			   	}
			   	.fourvideo-top{
			   		width: 90%;
			   		height: auto;
			   		margin: 10px auto 0;
			   		display: flex;
			   		flex-direction: column;
			   		justify-content: flex-end;
			   	}
			   	.fourvideo-top div{
			   		text-align: left;
			   	}
			   	.fourvideo-top div:nth-of-type(1){
			   		font-size: 20px;
			   		font-weight: bold;
			   		line-height: 34px;
			   		color: #000000;
			   	}
			   	.fourvideo-top div:nth-of-type(2){
			   		font-size: 16px;
			   		color: #777e87;
			   	}
			   	.fourvideo-bottom{
			   		width: 90%;
					height: 80%;
			   		margin: 20px auto 0;
					overflow: hidden;
					display: flex;
					justify-content: space-between;
			   	}
			   	.fourvideo-video1{
			   		width: 51%;
			   		height: 100%;
			   		overflow: hidden;
			   		border-radius: 20px;
			   	}

			   	.fourvideo-video1 img{
					width: auto;
			   		height: 100%;
			 		border-radius: 20px;
			   	}
			 
			   	.fourvideo-video3{
			   		width: 47%;
			   		height: 100%;
			   		display: flex;
			   		flex-direction: column;
			   		justify-content: space-between;
			   	}
			   	.fourvideo-video4{
			   		width: 100%;
			   		height: 51%;
			   		border-radius: 20px;
			   		overflow: hidden;
			   	}
				.fourvideo-video4 img{
					width: 101%;
					height: auto;
					margin-left: -5px;
				}
			 	.fourvideo-video5{
			 		width: 100%;
			 		height: 46%;
			 		border-radius: 20px;
			 		overflow: hidden;
			 	}
				.fourvideo-video5 img{
					width: 100%;
					height: auto;
				}
			 	/* .fourvideo-video4:hover{
			 		height: 60%;
			 		transition: 0.4s;
			 	}
			 	.fourvideo-video4:hover + .fourvideo-video5{
			 		height: 37%;
			 		transition: 0.4s;
			 		overflow: hidden;
			 	} */
			   	/* 新增第三屏 */
				.newthirteen-wrap{
					width: 100%;
					/* height: 100vh; */
					height: inherit;
					position: relative;
					display: flex;
					justify-content: space-between;
					overflow: hidden;
					box-sizing: border-box;
				}
				.newthirteen-bgimg{
					position: absolute;
					right: 0;
					top: 0;
					z-index: 1;
					background-color: yellow;
				}
				 
				.newthirteen-bgimg img:nth-of-type(1){
					width: 750px;
					height: 750px;
					position: absolute;
					/* right: 200px;
					top: 100px; */
					right: 300px;
					top: -50px;
					opacity: 0.2;
				} 
				.newthirteen-bgimg img:nth-of-type(2){
					width: 750px;
					height: 750px;
					position: absolute;
					/* right: -280px;
					top: 100px; */
					right: -280px;
					top: 200px; 
					opacity: 0.2;
				} 
				.newthirteen-left{
					width: 50%;
					height: 100%;
					position: absolute;
					right: 0;
					top: 0;
					display: flex;
					flex-direction: column;
					justify-content: flex-start;
					padding-top: 50px;
					box-sizing: border-box;
					z-index: 4;
				}
				.newthirteen-title{
					font-size: 20px;
					font-weight: bold;
					line-height: 50px;
					color: #000000;
				}
				.newthirteen-text{
					font-size: 16px;
					color: #777e87;
				}
				.newthirteen-right{
					width: 100%;
					/* height: 100vh; */
					height: inherit;
					z-index: 3;
					position: absolute;
					top: 0;
					left: 0;
				}
				.newthirteen-rightvideo{
					width: 100%;
					height: 100%;
					background-image: url(https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/secondyanpc.png);
					background-repeat: no-repeat;
					background-size: auto 100%;
				}
			
			   	/* 第四屏 */
				.box3{
					height: inherit;
					overflow: hidden;
				}
			   	.thirteen-wrap{
			   		width: 100%;
			   		/* height: 100vh; */
					height: inherit;
			   		position: relative;
			   		display: block;
			   		/* justify-content: space-between; */
			   		overflow: hidden;
			   		box-sizing: border-box;
			   	}
			   	.thirteen-bgimg{
			   		position: absolute;
			   		left: 0;
			   		top: 0;
			   		z-index: 1;
			   		background-color: yellow;
			   	}
			 
			   	.thirteen-bgimg img:nth-of-type(1){
			   		width: 750px;
			   		height: 750px;
			   		position: absolute;
			   		left: -400px;
			   		top: -100px;
			   		opacity: 0.2;
			   	} 
			   	.thirteen-bgimg img:nth-of-type(2){
			   		width: 750px;
			   		height: 750px;
			   		position: absolute;
			   		left: 300px;
			   		top: 30px;
			   		opacity: 0.2;
			   	} 
			   	.thirteen-left{
			   		width: 100%;
			   		height: auto;
			   		display: flex;
			   		flex-direction: column;
			   		justify-content: center;
			   		z-index: 4;
					padding-top: 30px;
					box-sizing: border-box;
			   	}
			   	.thirteen-title{
			   		font-size: 22px;
			   		font-weight: bold;
			   		line-height: 40px;
			   		color: #000000;
			   	}
			   	.thirteen-text{
			   		font-size: 20px;
			   		color: #777e87;
			   	}
			   	.thirteen-right{
			   		width: 100%;
			   		height: 80%;
			   		display: flex;
					justify-content: center;
			   		align-items: center;
			   		z-index: 100;
			   		position: relative;
			   	}
			   	.thirteen-rightvideo{
			   		width: 20%;
					height: inherit;
					background-color: yellow;
			   		/* height: auto; */
			   		/* overflow: hidden; */
					display: flex;
					justify-content: center;
					align-items: center;
			   		border-radius: 20px;
			   	}
			   	.thirteen-rightvideo img{
			   		width: 100%;
					height: auto;
					border-radius: 20px;
			   	}
			   	.thirteen-img1{
			   		width: 80px;
			   		height: 80px;
			   		position: absolute;
			   		left: 360px;
			   		top: 30px;
			   	}
			   	.thirteen-img2{
			   		width: 110px;
			   		height: 120px;
			   		position: absolute;
			   		right: 340px;
			   		top: 30px;
			   	}
			   	.thirteen-img3{
			   		width: 100px;
			   		height: 100px;
			   		position: absolute;
			   		left: 350px;
			   		bottom: 20px;
			   	}
			   	.thirteen-img4{
			   		width: 100px;
			   		height: 100px;
			   		position: absolute;
			   		right: 350px;
			   		bottom: 20px;
			   	}
			   	
			   	/* 第五屏 */
				.box4{
					height: inherit;
				}
			   	.four-wrap{
			   		width: 100%;
			   		/* height: 100vh; */
					height: inherit;
			   		position: relative;
			   		overflow: hidden;
			   		box-sizing: border-box;
			   		display: block;
			   	}
			   	.four-bgimg{
			   		position: absolute;
			   		left: 0;
			   		top: 0;
			   		z-index: 1;
			   		/* transform: translate(-550px,-450px) scale(1.2,1.2);	 */
			   	}
			   	.four-bgimg img:nth-of-type(1){
			   		width: 750px;
			   		height: 750px;
			   		position: absolute;
			   		left: -200px;
			   		top: 30px;
			   		opacity: 0.2;
			   	} 
			   	.four-bgimg img:nth-of-type(2){
			   		width: 750px;
			   		height: 750px;
			   		position: absolute;
			   		left: 200px;
			   		top: 30px;
			   		opacity: 0.2;
			   	} 
			   	.four-left{
			   		width: 100%;
			   		height: auto;
			   		display: flex;
			   		flex-direction: column;
			   		z-index: 4;
			   	}
			   	.four-title{
			   		font-size: 22px;
			   		font-weight: bold;
			   		line-height: 40px;
			   		color: #000000;
			   		margin-top: 30px;
			   	}
			   	.four-text{
			   		font-size: 20px;
			   		color: #777e87;
					margin-top: 0px;
			   	}
			   	
			   	.four-right{
			   		width: 70%;
					margin: 0 auto;
			   		height: inherit;
					box-sizing: border-box;
			   	}
			   	.four-rightscroll{
			   		width: 100%;
					height: inherit;
					overflow: hidden;
			   		display: flex;
			   		justify-content: space-between;
			   	}
			   	.four-rightlist1{
			   		width: 32%;
			   		padding-top: 150px;
					box-sizing: border-box;
			   	}
			   	.four-rightlist2{
			   		width: 32%;
			   		padding-top: 50px;
					box-sizing: border-box;
			   	}
			   	.four-rightlist3{
			   		width: 32%;
			   		padding-top: 100px;
					box-sizing: border-box;
			   	}
			   	/* 右侧流式加载视频 */
			   	.four-leftvideo{
			   		width: 100%;
			   		margin-bottom: 20px;
			   	}
			   	.four-leftvideo img{
			   		width: 100%;
			   		border-radius: 20px;
			   	}
			   	
			   	/* 第六屏 */
				.box5{
					height: inherit;
				}
			   	.news-wrap{
			   		width: 100%;
			   		/* height: calc( 100vh - 200px ); */
					height: 70%;
			   	}
			   	.news-top{
			   		width: 93%;
			   		height: auto;
			   		margin: 0 auto;
			   		display: flex;
			   		justify-content: space-between;
			   		align-items: center;
			   		z-index: 4;
			   	}
			   	.news-toptitle{
			   		font-size: 22px;
			   		font-weight: bold;
			   		line-height: 70px;
			   		color: #000000;
			   	}
			   	.news-more{
			   		font-size: 16px;
			   		color: #B4B2B2;
			   	}
			   	.news-more:hover{
			   		cursor: pointer;
			   	}
			   	.news-bottom{
			   		width: 93%;
					height: 80%;
					overflow: hidden;
			   		margin: 10px auto 0;
			   		display: flex;
			   		justify-content: space-between;
			   	}
			   	.news-list{
			   		width: 31%;
					height: 100%;
					padding: 0 0px;
					box-sizing: border-box;
					margin-bottom: 20px;
			   	}
			   	.news-img{
			   		width: 100%;
			   		height: 50%;
			   		border-radius: 20px;
			   		overflow: hidden;
			   	}
			   	.news-img img{
			   		width: 100%;
			   		height: auto;
			   	}
			   	.news-word{
			   		width: 100%;
			   	}
			   	.news-title{
			   		font-size: 20px;
			   		font-weight: bold;
			   		line-height: 50px;
			   		color: #000000;
			   		overflow: hidden;
			   		  text-overflow: ellipsis;
			   		  white-space:nowrap; 
			   	}
			   	.news-text{
			   		line-height: 2.3vw;
			   		font-size: 1.5vw;
			   		color: #777e87;
			   		text-align: justify;
			   		letter-spacing: .06em;
			   		  word-break: break-all;
			   		  text-overflow: ellipsis;
			   		  display: -webkit-box;
			   		  -webkit-box-orient: vertical;
			   		  -webkit-line-clamp: 2; /* 这里是超出几行省略 */
			   		  overflow: hidden;
			   	}
		}
</style>