<template>
	<div class="commentzuiwai">
		<div class="commenticon" @click="opencommen" v-if="!commentshow">
			<!-- <span class="iconfont icon-bianpinghuatubiaosheji-"></span> -->
			<img src="../assets/img/commenicon.png" />
			<div>给我留言</div>
		</div>
		
		
		<div class="commentCenter" v-if="commentshow">
			<div class="commentContent" :class="[commentshow?'showcommen':'']">
				<div class="toptext">
					<div class="toptextspan">向</div>
					<div class="toptextword">TA</div>
					<div class="toptextspan">发起</div>
					<div class="toptextword">1</div>
					<div class="toptextspan">对</div>
					<div class="toptextword">1</div>
					<div class="toptextspan">需求</div>
				</div>
				<div class="topimg">
					<img src="../assets/img/weixin.png" />
					<div class="topimgright">
						<div class="topimgtop">
							<div class="topimgtoptext">北京后天科技有限公司</div>
							<div class="topimgtopword">北京</div>
							<div class="topimgtopword">刚刚</div>
						</div>
						<div class="topimgbot">
							<div>回复时间 1小时内</div>
							<div>回复率 100%</div>
						</div>
					</div>
				</div>
				
				<div class="commentTextWrap">
					<textarea class="commentText" maxlength="300" placeholder="请简单描述您的需求，例如：我需要制作一个宣传片，3-5分钟，4K清晰度..." v-model="commenarea" v-on:focus="changearea"></textarea>
					<div v-if="textnull">*请输入内容</div>
				</div>
				
				<div class="commentusers">
					<div class="commentinputdiv1">
						<input class="commentname" v-model="commendidian" v-on:input="changedidian" placeholder="项目地点"/>
						<div v-if="didiannul">*请输入项目地点</div>
					</div>
					<div class="commentinputdiv2">
						<div class="commentinputdiv2center">
							<div class="yusuanleft">
								<div>项目预算</div>
								<div>|</div>
							</div>
							<input v-model="commenyusuan" v-on:input="changeyusuan" class="yusuaninput" placeholder="输入预算" />
							<div class="yusuanyuan">元</div>
							<div class="mianyi">
								<input class="mianyibtn" type="checkbox" v-model="checks" @click = "checkbox()" />
								<div>面议</div>
							</div>
						</div>
						
						<div class="commentinputdiv2err" v-if="yusuannull">*请输入项目预算</div>
					</div>
				</div>
				
				<div class="commentusers">
					<div class="commentinputdiv">
						<input class="commentname" placeholder="您的称呼" v-model="commenname" v-on:input="changename" />
						<div v-if="namenull">*请输入姓名</div>
					</div>
					<div class="commentinputdiv3">
						<input class="commentphone" placeholder="手机号码" v-model="commenphone" v-on:input="changephnoe" />
						<div v-if="phonenull">*请输入联系方式</div>
					</div>
				</div>
				
				<div class="commentBtn">
					<div class="commentClose" @click="closecommshow">取消</div>
					<div class="commentSend" @click="commenupload">提交</div>
				</div>
				
				<div class="closeicon" @click="closecommshow">
					<span class="iconfont icon-gary"></span>
				</div>
			</div>
			
		</div>
		
	</div>
</template>

<script>
	import $ from "jquery"
	export default {
	  name: 'Headernew',
	  props: {
	    msg: String
	  },
	  data() {
		return {
			commentshow:false,
			didiannul:false,
			yusuannull:false,
			checks:false,//面议选项框是否被选中
			namenull:false,
			phonenull:false,
			textnull:false,
			commendidian:'',//项目地点
			commenyusuan:'',//项目预算
			commenname:'',//用户名
			commenphone:'',//手机号
			commenarea:'',//评论内容
			
		}
	  },
	  mounted() {

	  },
	  methods:{
		  opencommen:function(){
			// var usersmessage = localStorage.getItem('usermessage');
			// if(usersmessage){
			// 	var userid = JSON.parse(usersmessage).userid;
			// 	this.commentshow = true
			// }else{
			// 	alert('请登录后，再进行留言！')
			// }
			  this.commentshow = true
		  },
		  closecommshow:function(){
			  this.commentshow = false
			  this.namenull = false
			  this.phonenull = false
			  this.textnull = false
		  },
		  changedidian:function(){
			  this.didiannul = false
		  },
		  changeyusuan:function(){
		  	  this.yusuannull = false
		  },
		  checkbox:function(){
			  // 假如面议被选中，就把输入预算输入框置为空
			  var checktrue = event.target.checked
			  if(checktrue){
				  this.commenyusuan = ''
			  }
		  },
		  changename:function(){
			  this.namenull = false
		  },
		  changephnoe:function(){
		  	  this.phonenull = false
		  },
		  changearea:function(){
			  this.textnull = false
		  },
		  commenupload:function(){
			 var didian = this.commendidian
			 var yusuan = this.commenyusuan
			 var yusuanbtn = this.checks
			 var name = this.commenname
			 var phone = this.commenphone
			 var text = this.commenarea
			 console.log(didian,yusuan,yusuanbtn,name,phone,text)
			 var usersmessage = localStorage.getItem('usermessage');
			 var userid = JSON.parse(usersmessage).userid;
			 
			 if(name==''){
				 this.namenull = true
			 }else if(phone==''){
				 this.phonenull = true
			 }else if(text==''){
				 this.textnull = true
			 }else if(didian==''){
				 this.didiannul = true
			 }else if(yusuan=='' && !yusuanbtn){
				 this.yusuannull = true
			 }else {
				 let newurl = this.$api + "/save/saveEmail"
				 this.$axios.post(newurl,{
				 		user_id:userid,
						address:didian,//项目地点
						spend:yusuan, //预算
						on_DP:yusuanbtn,//是否面谈
				 		name:name,
						phone:phone,
						detail:text
				 })
				 .then((res) => {
				 	console.log(res)
					this.commenname = ''
					this.commenphone = ""
					this.commenarea = ""
					this.commentshow = false
					alert('提交成功！')
				 })
				 .catch((err) => {
				 	console.log(err) //错误信息
				 })
			 }
		  },
	  }
	}
</script>

<style>
	@import '../assets/css/alibaba.css';
	.commentzuiwai{
		position: relative;
	}
	.commenticon{
		width: 70px;
		height: 70px;
		position: fixed;
		right: 10px;
		top: 600px;
		z-index: 110;
	}
	.commenticon:hover{
		cursor: pointer;
	}
	.commenticon div{
		width: 100%;
		font-size: 11px;
		color: #fff;
		background-color: #01AEEC;
		border-radius: 4px;
		padding: 1px 0;
	}
	.commenticon img{
		width: 50px;
		height: 50px;
	}
	.commentCenter{
		width: 100%;
		overflow: hidden;
		height: 100vh;
		z-index: 100;
		position: fixed;
		left: 0;
		top: 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	
	.commentYin{
		width: 100vw;
		height: 100vh;
		background: rgba(0,0,0,0.5);
		position: absolute;
		left: 0;
		top: 0;
		z-index: 1;
	}
	
	.commentContent{
		width: 800px;
		height: 530px;
		background-color: #fff;
		z-index: 2;
		margin-top: -1500px;
		border-radius: 8px;
		position: relative;
	}
	/* 顶部文字 */
	.toptext{
		width: 92%;
		margin: 15px auto 0;
		display: flex;
		align-items: center;
	}
	.toptextspan{
		font-size: 16px;
		line-height: 20px;
	}
	.toptextword{
		font-size: 16px;
		line-height: 20px;
		font-weight: bold;
		margin: 0 5px;
	}
	/* 顶部图片区 */
	.topimg{
		width: 92%;
		margin: 15px auto 0;
		display: flex;
	}
	.topimg img{
		width: 50px;
	}
	.topimgright{
		margin-left: 10px;
	}
	.topimgtop{
		display: flex;
	}
	.topimgtoptext{
		font-size: 16px;
		line-height: 25px;
	}
	.topimgtopword{
		font-size: 16px;
		line-height: 25px;
		color: #A6A3A3;
		margin-left: 10px;
	}
	.topimgbot{
		text-align: left;
		font-size: 14px;
		line-height: 25px;
		color: #A6A3A3;
		margin-left: 2px;
		display: flex;
	}
	.topimgbot div:nth-of-type(2){
		margin-left: 15px;
	}
	
	.showcommen{
	  animation:commmove 0.4s ease-in-out 0s 1 forwards;
	}
	@keyframes commmove{   
	  0%{margin-top: -1500px;}
	  100%{margin-top: 0px;}
	}
	.commentusers{
		width: 92%;
		margin: 5px auto 0;
		display: flex;
		justify-content: space-between;
	}
	.commentinputdiv{
		width: 41%;
		text-align: left;
	}
	.commentinputdiv3{
		width: 58%;
		text-align: left;
	}
	.commentinputdiv3 div{
		font-size: 14px;
		color: red;
	}
	.commentinputdiv1{
		width: 41%;
		text-align: left;
	}
	.commentinputdiv1 div{
		font-size: 14px;
		color: red;
	}
	.commentinputdiv2{
		width: 58%;
		height: 100%;
		
	}
	.commentinputdiv2center{
		text-align: left;
		border: #767676 1px solid;
		border-radius: 2px;
		padding: 10px;
		box-sizing: border-box;
		display: flex;
		align-items: center;
	}
	.commentinputdiv2err{
		font-size: 14px;
		color: red;
		text-align: left;
	}
	.yusuanleft{
		height: 100%;
		display: flex;
		align-items: center;
	}
	.yusuanleft div{
		font-size: 14px;
		color: #767676;
	}
	.yusuanleft div:nth-of-type(2){
		margin-left: 10px;
	}
	.yusuaninput{
		width: 28% !important;
		height: 70% !important;
		border: none;
	}
	.yusuanyuan{
		font-size: 14px;
		color: #767676;
		margin-left: 5px;
	}
	.mianyi{
		width: 20%;
		display: flex;
		align-items: center;
		margin-left: 90px;
	}
	.mianyibtn{
		width: 15px !important;
		height: 15px !important;
		margin: 0 !important;
	}
	.mianyi div{
		font-size: 14px;
		color: #767676;
		margin-left: 5px;
	}
	.closeicon{
		position: absolute;
		right: -14px;
		top: -14px;
		border-radius: 50%;
		width: 31px;
		height: 31px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #fff;
	}
	.icon-gary{
		font-size: 30px;
		line-height: 30px;
	}
	
	.commentusers input{
		width: 100%;
		height: 40px;
		outline: none;
		padding-left: 10px;
		box-sizing: border-box;
	}
	.commentinputdiv div{
		font-size: 13px;
		color: red;
		margin-top: 5px;
	}
	.commentTextWrap{
		width: 92%;
		margin: 30px auto 0;
		text-align: left;
	}
	.commentText{
		width: 100%;
		height: 150px;
		outline: none;
		padding: 15px;
		box-sizing: border-box;
		font-size: 14px;
		background-color: #f7f7f7;
	}
	.commentTextWrap div{
		font-size: 13px;
		color: red;
		margin-top: 0;
	}
	.commentBtn{
		width: 92%;
		margin: 30px auto;
		display: flex;
		justify-content: center;
	}
	.commentBtn div{
		width: 35%;
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 5px;
	}
	.commentBtn div:hover{
		cursor: pointer;
	}
	.commentClose{
		background-color: #eeeeee;
		color: #000;
	}
	.commentSend{
		background-color: #007AFF;
		color: #fff;
		margin-left: 80px;
	}
	
	/* 手机端样式 */
	@media (min-width: 300px) and (max-width: 500px){
		.commentzuiwai{
			position: relative;
		}
		.commenticon{
			width: 55px;
			height: 70px;
			position: fixed;
			right: 5px;
			top: 500px;
			z-index: 110;
		}
		.commenticon:hover{
			cursor: pointer;
		}
		.commenticon div{
			font-size: 10px;
			color: #fff;
			border-radius: 2px;
			padding: 1px 0;
		}
		.commenticon img{
			width: 30px;
			height: 30px;
		}
		.commentCenter{
			width: 100vw;
			height: 100vh;
			z-index: 100;
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.commentYin{
			width: 100vw;
			height: 100vh;
			background: rgba(0,0,0,0.5);
			position: absolute;
			left: 0;
			top: 0;
			z-index: 1;
		}
		
		.commentContent{
			width: 80%;
			height: 530px;
			background-color: #fff;
			z-index: 2;
			margin-top: -1500px;
			border-radius: 8px;
		}
		/* 顶部文字 */
		.toptext{
			width: 92%;
			margin: 10px auto 0;
			display: flex;
			align-items: center;
		}
		.toptextspan{
			font-size: 14px;
			line-height: 14px;
		}
		.toptextword{
			font-size: 14px;
			line-height: 14px;
			font-weight: bold;
			margin: 0 5px;
		}
		/* 顶部图片区 */
		.topimg{
			width: 92%;
			height: 38px;
			margin: 10px auto 0;
			display: flex;
		}
		.topimg img{
			width: 38px;
			height: auto;
		}
		.topimgright{
			margin-left: 5px;
		}
		.topimgtop{
			display: flex;
		}
		.topimgtoptext{
			font-size: 14px;
			line-height: 20px;
		}
		.topimgtopword{
			font-size: 14px;
			line-height: 20px;
			color: #A6A3A3;
			margin-left: 5px;
		}
		.topimgbot{
			text-align: left;
			font-size: 13px;
			line-height: 20px;
			color: #A6A3A3;
			margin-left: 2px;
		}
		
		
		.showcommen{
		  animation:commmove 0.4s ease-in-out 0s 1 forwards;
		}
		@keyframes commmove{   
		  0%{margin-top: -1500px;}
		  100%{margin-top: 0px;}
		}
		.commentusers{
			width: 92%;
			margin: 5px auto 0;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}
		.commentinputdiv{
			width: 48%;
			text-align: left;
		}
		.commentinputdiv div{
			font-size: 12px !important;
			line-height: 12px;
		}
		.commentinputdiv3{
			width: 48%;
		}
		.commentinputdiv3 div{
			font-size: 12px;
			color: red;
		}
		.commentinputdiv1{
			width: 100%;
			/* height: 40px; */
			text-align: left;
		}
		.commentinputdiv1 div{
			font-size: 12px;
		}
		.commentinputdiv2{
			width: 100%;
		}
		.commentinputdiv2center{
			width: 100%;
			height: 40px;
			margin-top: 5px;
			text-align: left;
			border: #767676 1px solid;
			border-radius: 2px;
			padding: 10px;
			box-sizing: border-box;
			display: flex;
			align-items: center;
		}
		.commentinputdiv2err{
			font-size: 12px;
		}
		.yusuanleft{
			width: 30%;
			display: flex;
			align-items: center;
		}
		.yusuanleft div{
			font-size: 13px !important;
			color: #767676;
		}
		.yusuanleft div:nth-of-type(2){
			margin-left: 8px;
		}
		.yusuaninput{
			width: 36% !important;
			height: 70% !important;
			border: none;
		}
		.yusuanyuan{
			font-size: 14px;
			color: #767676;
			margin-left: 5px;
		}
		.mianyi{
			width: 23%;
			display: flex;
			align-items: center;
			margin-left: 40px;
		}
		.mianyibtn{
			width: 15px !important;
			height: 15px !important;
			margin: 0 !important;
		}
		.mianyi div{
			font-size: 14px;
			color: #767676;
			margin-left: 5px;
		}
		.closeicon{
			position: absolute;
			right: -14px;
			top: -14px;
			border-radius: 50%;
			width: 31px;
			height: 31px;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: #fff;
		}
		.icon-gary{
			font-size: 30px;
			line-height: 30px;
		}
		
		
		
		.commentusers input{
			width: 100%;
			height: 40px;
			outline: none;
			padding-left: 10px;
			box-sizing: border-box;
		}
		/* .commentinputdiv div{
			font-size: 13px;
			color: red;
			margin-top: 5px;
		} */
		.commentTextWrap{
			width: 92%;
			margin: 15px auto 0;
			text-align: left;
		}
		.commentText{
			width: 100%;
			height: 170px;
			outline: none;
			padding: 15px;
			box-sizing: border-box;
			font-size: 14px;
		}
		.commentTextWrap div{
			font-size: 12px;
			color: red;
			margin-top: 0;
		}
		.commentBtn{
			width: 92%;
			margin: 20px auto;
			display: flex;
			justify-content: center;
		}
		.commentBtn div{
			width: 40%;
			height: 40px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 5px;
		}
		.commentBtn div:hover{
			cursor: pointer;
		}
		.commentClose{
			background-color: #eeeeee;
			color: #000;
		}
		.commentSend{
			background-color: #007AFF;
			color: #fff;
			margin-left: 80px;
		}
	}
	/* pad端样式 */
	@media (min-width: 500px) and (max-width: 800px){
		.commentzuiwai{
			position: relative;
		}
		.commenticon{
			width: 60px;
			height: 70px;
			position: fixed;
			right: 15px;
			top: 600px;
			z-index: 110;
		}
		.commenticon:hover{
			cursor: pointer;
		}
		.commenticon div{
			font-size: 11px;
			color: #fff;
			border-radius: 2px;
			padding: 2px 0;
		}
		.commenticon img{
			width: 50px;
			height: 50px;
		}
		.commentCenter{
			width: 100vw;
			height: 100vh;
			z-index: 100;
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.commentYin{
			width: 100vw;
			height: 100vh;
			background: rgba(0,0,0,0.5);
			position: absolute;
			left: 0;
			top: 0;
			z-index: 1;
		}
		
		.commentContent{
			width: 70%;
			height:550px;
			background-color: #fff;
			z-index: 2;
			margin-top: -1500px;
			border-radius: 8px;
		}
		.showcommen{
		  animation:commmove 0.4s ease-in-out 0s 1 forwards;
		}
		@keyframes commmove{   
		  0%{margin-top: -1500px;}
		  100%{margin-top: 0px;}
		}
		.commentusers{
			width: 92%;
			margin: 15px auto 0;
			display: flex;
			justify-content: space-between;
		}
		.commentinputdiv{
			width: 40%;
			text-align: left;
		}
		.commentinputdiv div{
			font-size: 13px;
			margin: 0 !important;
		}
		.commentinputdiv3{
			width: 58%;
		}
		.commentinputdiv3{
			font-size: 13px;
			color: red;
		}
		.commentusers input{
			width: 100%;
			height: 40px;
			outline: none;
			padding-left: 10px;
			box-sizing: border-box;
		}
		.commentTextWrap{
			width: 92%;
			margin: 20px auto 20px;
			text-align: left;
		}
		.commentText{
			width: 100%;
			height: 200px;
			outline: none;
			padding: 15px;
			box-sizing: border-box;
			font-size: 14px;
		}
		.commentTextWrap div{
			font-size: 13px;
			color: red;
			margin-top: 0;
		}
		.commentBtn{
			width: 92%;
			margin: 30px auto;
			display: flex;
			justify-content: center;
		}
		.commentBtn div{
			width: 30%;
			height: 40px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 5px;
		}
		.commentBtn div:hover{
			cursor: pointer;
		}
		.commentClose{
			background-color: #eeeeee;
			color: #000;
		}
		.commentSend{
			background-color: #007AFF;
			color: #fff;
			margin-left: 80px;
		}
		
		.commentinputdiv1{
			width: 40%;
			text-align: left;
		}
		.commentinputdiv1 div{
			font-size: 13px;
		}
		.commentinputdiv2{
			width: 58%;
			height: auto;
		}
		.commentinputdiv2center{
			width: 100%;
			height: 40px;
			text-align: left;
			border: #767676 1px solid;
			border-radius: 2px;
			padding: 10px;
			box-sizing: border-box;
			display: flex;
			align-items: center;
		}
		.commentinputdiv2err{
			font-size: 13px;
		}
		.yusuanleft{
			width: 40%;
			display: flex;
			align-items: center;
		}
		.yusuanleft div:nth-of-type(2){
			margin-left: 7px;
		}
		.yusuaninput{
			width: 36% !important;
			height: 70% !important;
			border: none;
		}
		.mianyi{
			width: 30%;
			display: flex;
			align-items: center;
			margin-left: 30px;
		}
	}
	
	/* 老板仓定制机的样式 */
	@media (min-width: 1000px) and (max-width: 1300px){
		.commenticon{
			width: 65px;
			height: 65px;
			position: fixed;
			right: 10px;
			top: auto;
			bottom: 150px;
			z-index: 110;
		}
		.commenticon div{
			width: 100%;
			font-size: 11px;
			color: #fff;
			background-color: #01AEEC;
			border-radius: 4px;
			padding: 1px 0;
		}
		.commenticon img{
			width: 40px;
			height: 40px;
		}
		
	}
</style>
