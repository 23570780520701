import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',//首页
    name: 'Home',
    component: Home
  },
  {
    path: '/videolist',//视频列表页
    name: 'videolist',
    component: () => import(/* webpackChunkName: "about" */ '../views/videolist.vue')
  },
  {
    path: '/viplist',//vip视频列表页，登录后
    name: 'viplist',
    component: () => import(/* webpackChunkName: "about" */ '../views/viplist.vue')
  },
  {
    path: '/casecontent',//视频详情页
    name: 'casecontent',
    component: () => import(/* webpackChunkName: "about" */ '../views/casecontent.vue')
  },
  {
    path: '/about',//联系我们
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/about.vue')
  },
  {
    path: '/mediapage',//视频传媒页面
    name: 'mediapage',
    component: () => import(/* webpackChunkName: "about" */ '../views/mediapage.vue')
  },
  {
    path: '/interactpage',//智能交互页面
    name: 'interactpage',
    component: () => import(/* webpackChunkName: "about" */ '../views/interactpage.vue')
  },
  {
    path: '/ceshi',//地图测试页面
    name: 'ceshi',
    component: () => import(/* webpackChunkName: "about" */ '../views/ceshi.vue')
  },
  {
    path: '/codepage',//微信登录二维码页面
    name: 'codepage',
    component: () => import(/* webpackChunkName: "about" */ '../views/codepage.vue')
  },
  {
    path: '/loginsucess',//微信登录二维码页面
    name: 'loginsucess',
    component: () => import(/* webpackChunkName: "about" */ '../views/loginsucess.vue')
  },
  {
    path: '/articledetail',//文章详情页面
    name: 'articledetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/articledetail.vue')
  },
  {
    path: '/articlelist',//新闻列表页面
    name: 'articlelist',
    component: () => import(/* webpackChunkName: "about" */ '../views/articlelist.vue')
  },
  {
    path: '/echart',//转发和浏览记录表格
    name: 'echart',
    component: () => import(/* webpackChunkName: "about" */ '../views/echart.vue')
  },
  {
    path: '/echartdetail',//转发和浏览记录树形图
    name: 'echartdetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/echartdetail.vue')
  },
  {
    path: '/signlist',//方案验收列表页
    name: 'signlist',
    component: () => import(/* webpackChunkName: "about" */ '../views/sign/signlist.vue')
  },
  {
    path: '/signdetail',//方案验收详情页页
    name: 'signdetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/sign/signdetail.vue')
  },
  {
    path: '/signdetailadd',//方案验收详情页---增加新列表
    name: 'signdetailadd',
    component: () => import(/* webpackChunkName: "about" */ '../views/sign/signdetailadd.vue')
  },
  {
    path: '/signlook',//方案验收--浏览记录表
    name: 'signlook',
    component: () => import(/* webpackChunkName: "about" */ '../views/sign/signlook.vue')
  },
  {
    path: '/signsure',//方案验收--签收记录表
    name: 'signsure',
    component: () => import(/* webpackChunkName: "about" */ '../views/sign/signsure.vue')
  },
  {
    path: '/signpeople',//方案验收--已经签收者的详情，查看用
    name: 'signpeople',
    component: () => import(/* webpackChunkName: "about" */ '../views/sign/signpeople.vue')
  },
  {
    path: '/printsign',//方案验收详情页---打印页
    name: 'printsign',
    component: () => import(/* webpackChunkName: "about" */ '../views/sign/printsign.vue')
  },
  {
    path: '/checklist',//方案签收列表页
    name: 'checklist',
    component: () => import(/* webpackChunkName: "about" */ '../views/plan/checklist.vue')
  },
  {
    path: '/checkdetail',//方案签收详情页
    name: 'checkdetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/plan/checkdetail.vue')
  },
  {
    path: '/printcheck',//方案签收详情页---打印页
    name: 'printcheck',
    component: () => import(/* webpackChunkName: "about" */ '../views/plan/printcheck.vue')
  },
  {
    path: '/checklook',//方案签收浏览记录页面
    name: 'checklook',
    component: () => import(/* webpackChunkName: "about" */ '../views/plan/checklook.vue')
  },
  {
    path: '/checksure',//方案签收确认记录表
    name: 'checksure',
    component: () => import(/* webpackChunkName: "about" */ '../views/plan/checksure.vue')
  },
  {
    path: '/checkpeople',//方案签收详情--已签收人详情-查看用
    name: 'checkpeople',
    component: () => import(/* webpackChunkName: "about" */ '../views/plan/checkpeople.vue')
  },
  {
    path: '/camerlist',//分镜确认单列表页
    name: 'camerlist',
    component: () => import(/* webpackChunkName: "about" */ '../views/fenjing/camerlist.vue')
  },
  {
    path: '/camerdetail',//分镜确认单详情页
    name: 'camerdetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/fenjing/camerdetail.vue')
  },
  {
    path: '/printcamer',//分镜确认单详情页---打印页
    name: 'printcamer',
    component: () => import(/* webpackChunkName: "about" */ '../views/fenjing/printcamer.vue')
  },
  {
    path: '/dublist',//配音文稿确认单列表页
    name: 'dublist',
    component: () => import(/* webpackChunkName: "about" */ '../views/peiyin/dublist.vue')
  },
  {
    path: '/dubdetail',//配音文稿确认单详情页
    name: 'dubdetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/peiyin/dubdetail.vue')
  },
  {
    path: '/printdub',//配音文稿确认单详情页---打印页
    name: 'printdub',
    component: () => import(/* webpackChunkName: "about" */ '../views/peiyin/printdub.vue')
  },
  {
    path: '/imgceshi',//分镜确认单详情页
    name: 'imgceshi',
    component: () => import(/* webpackChunkName: "about" */ '../views/fenjing/imgceshi.vue')
  },
  {
    path: '/redbox',//盲盒红包页面
    name: 'redbox',
    component: () => import(/* webpackChunkName: "about" */ '../views/randombox/redbox.vue')
  },
  {
    path: '/redboxceshi',//盲盒红包测试红包功能页面
    name: 'redboxceshi',
    component: () => import(/* webpackChunkName: "about" */ '../views/randombox/redboxceshi.vue')
  },
  {
	  path: '/brochure', // 宣传册
	  name: 'brochure',
	  component: () => import(/* webpackChunkName: "about" */ '../views/brochure.vue')
  },
  {
	  path: '/casedetail', // 宣传册
	  name: 'casedetail',
	  component: () => import(/* webpackChunkName: "about" */ '../views/casedetail.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
