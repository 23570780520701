<template>
	<div>
	<!-- PC端顶部导航栏 -->
	<div class="PCnavtop-wrap" v-show="navshow">
		<div class="PCnavtop-center">
			<div class="PCnavtop-centerleft">
				<img src="../assets/img/logo.png"/>
			</div>
			<div class="PCnavtop-centermiddle">
				<router-link to="/" class="PCnavtop-navlist1">首页</router-link>
				<router-link to="/mediapage" class="PCnavtop-navlist1">视频传媒</router-link>
				<router-link to="/interactpage" class="PCnavtop-navlist1">智能交互</router-link>
				<router-link to="/videolist" class="PCnavtop-navlist1">合作案例</router-link>
				<router-link to="/viplist" class="PCnavtop-navlist1" v-if="vipnavshow">VIP案例</router-link>
				<router-link to="/about" class="PCnavtop-navlist1">联系我们</router-link>
				<!-- <router-link to="/echart" class="PCnavtop-navlist1" v-if="vipnavshow">浏览转发</router-link> -->
				<!-- <router-link to="/officelist" class="PCnavtop-navlist1">项目方案</router-link> -->
				
				<div class="PCnavtop-navlist2" v-if="vipnavshow">
				<!-- <div class="PCnavtop-navlist2"> -->
					<div class="otherstop">
						<div>关于其他</div>
						<!-- <span class="iconfont icon-down21"></span> -->
					</div>
					<div class="otherslist">
						<router-link to="/echart" class="othersbotlist">浏览转发</router-link>
						<router-link to="/signlist" class="othersbotlist">方案验收</router-link>
						<router-link to="/checklist" class="othersbotlist">项目签收</router-link>
						<router-link to="/camerlist" class="othersbotlist">分镜确认</router-link>
						<router-link to="/dublist" class="othersbotlist">配音文稿</router-link>
					</div>
				</div>
				
				<!-- <el-menu 
				  :default-active="activeIndex" 
				  class="el-menu-demo" 
				  mode="horizontal" 
				  background-color="rgba(0,0,0,0)"
				  text-color='#fff'
				  @select="handleSelect">
				  <el-submenu index="2">
				    <template slot="title">其他导航</template>
				    <el-menu-item index="2-1" background-color="#fff">浏览转发</el-menu-item>
				    <el-menu-item index="2-2">项目方案</el-menu-item>
				    <el-menu-item index="2-3">签收方案</el-menu-item>
				  </el-submenu>
				</el-menu> -->
			</div>
			<div class="PCnavtop-centerright">
				<div class="gologin">
					<div><span class="iconfont icon-my1"></span></div>
					<div v-if="!username" @click="gotologin">微信登录</div>
					<div v-if="username">{{username}}</div>
				</div>
				<!-- <div v-if="username" @click="signout">退出登录</div> -->
				<div class="phonenum">
					<div><span class="iconfont icon-phonenew"></span></div>
					<div><span>13903126240 | 0312-3051117   | 15933970970</span></div>
				</div>
			</div>
		</div>

		 <!-- 移动端导航栏 -->
		  <div class="header-wrap">
			  <div class="menu-extras">
				  <button class="nav-mobile-button" @click="changeshow">
					<!-- <span class="icon-bar"></span>
					<span class="icon-bar"></span>
					<span class="icon-bar"></span> -->
					<img src="../assets/img/menu.png" />
				  </button>
			  </div>
			  <!-- <div class="logo-right">后天科技</div> -->
			  <div class="logo-right">
				  <img src="../assets/img/logo.png" />
			  </div>
		  </div>
		  
		  <div class="nav-mobilelist">
			  <!-- 一级导航 -->
			  <div class="navshow">
				  <router-link to="/" class="PCnavtop-navlist1">首页</router-link>
				  <router-link to="/mediapage" class="PCnavtop-navlist1">视频传媒</router-link>
				  <router-link to="/interactpage" class="PCnavtop-navlist1">智能交互</router-link>
				  <router-link to="/videolist" class="PCnavtop-navlist1">合作案例</router-link>
				  <router-link to="/viplist" class="PCnavtop-navlist1" v-if="vipnavshow">VIP案例</router-link>
				  <!-- <router-link to="/viplist" class="PCnavtop-navlist1">VIP案例</router-link> -->
				  <router-link to="/about" class="PCnavtop-navlist1">联系我们</router-link>
				  <a class="PCnavtop-navlist1" @click="showscondnav" v-if="vipnavshow">关于其他</a>
				  <!-- <router-link to="/officelist" class="PCnavtop-navlist1" v-if="vipnavshow">项目方案</router-link> -->
				  <router-link class="PCnavtop-navlist1" to="/redbox" v-if="vipnavshow">红包盲盒</router-link>
				  <div class="PCnavtop-navlist2" @click="goweixinlog" v-if="!username">
					  <span class="iconfont icon-weixin"></span>
					  <div>微信登录</div>
				  </div>
				  <div class="PCnavtop-navlist3" v-if="username">{{username}}</div>
				  <div class="PCnavtop-navlist4" v-if="username" @click="signout">退出登录</div>
			  </div>
			  <div class="navshowblack" @click="closeshow"></div>
			  
			  <!-- 二级导航 -->
			  <div class="secondnav" v-show="scondnavshows">
				  <div class="othershow">
					  <router-link to="/echart" class="PCnavtop-navlist1">浏览转发</router-link>
					  <router-link to="/signlist" class="PCnavtop-navlist1">方案验收</router-link>
					  <router-link to="/checklist" class="PCnavtop-navlist1">项目签收</router-link>
					  <router-link to="/camerlist" class="PCnavtop-navlist1">分镜确认</router-link>
					  <router-link to="/dublist" class="PCnavtop-navlist1">配音文稿</router-link>
				  </div>
				  <div class="otherblack" @click="closescondnav"></div>
			  </div>
			  
			  
		  </div>
		  
	</div>
	  
	 
  </div>
</template>

<script>
import $ from "jquery"
import wx from 'weixin-js-sdk'
export default {
  name: 'Headernew',
  props: {
    msg: String,
	navshow:{
		type:Boolean,
		default:true
	}
  },
  data() {
	return {
		username:'',
		vipnavshow:false,//控制vip案例导航的显示和隐藏
		scondnavshows:false,//控制移动端，二级导航的显示和隐藏
	}
  },
  mounted() {
	  //监听鼠标滚动事件
	  // window.addEventListener('mousewheel',this.handleScroll);
	  // 获取缓存中的用户名信息
	  var usersmessage = localStorage.getItem('usermessage');
	  if(usersmessage){
		  this.username =  JSON.parse(usersmessage).username
		  var admin = JSON.parse(usersmessage).adminid
		  // console.log(JSON.parse(usersmessage),admin)
		  if(admin==1){
			  this.vipnavshow = true
		  }else{
			  this.vipnavshow = false
		  }
		  
	  }
	   
  },
  methods:{
	  changeshow(){
		  if($('.nav-mobile-button').hasClass('rotate')){
			  $('.nav-mobile-button').removeClass('rotate');
			  $('.nav-mobile-button').addClass('rotate1');
			  $('.navshow').css('margin-left','-1000px')
			  $('.navshowblack').css('margin-left','-1000px')
		  }else{
			  $('.nav-mobile-button').removeClass('rotate1');
			  $('.nav-mobile-button').addClass('rotate');
			  $('.navshow').css('margin-left','0')
			  $('.navshowblack').css('margin-left','0')
		  }
	  },
	  closeshow(){
		  $('.navshow').css('margin-left','-1000px');
		  $('.navshowblack').css('margin-left','-1000px');
		  $('.nav-mobile-button').removeClass('rotate');
		  $('.nav-mobile-button').addClass('rotate1');
	  },
	  gotologin(){
		  if(localStorage.getItem('geotourl')){
		  	localStorage.removeItem('geotourl')
		  }
		  //指定跳转地址
		   this.$router.replace('/codepage')
	  },
	  goweixinlog(){
		  // 查看一下本地是否缓存了之前访问过的案例详情的url，有的话就删掉，防止跳转
		  // var backurl = localStorage.getItem('geotourl')
		  if(localStorage.getItem('geotourl')){
		  	localStorage.removeItem('geotourl')
		  }
		  // 移动端用的是公众号的appid，pc端用的是公众平台的appid
		  var appid = 'wx799c2495cc0d2a30'
		  var urlx = 'http%3A%2F%2Fxjaikj.htkjbd.com%2Floginsucess%3Fappid%3Dwx799c2495cc0d2a30'
		  // var appid = 'wx799c2495cc0d2a30'
		  // var urlx = 'http%3A%2F%2Fxjaikj.htkjbd.com%2Floginsucess%3Fappid%3Dwx799c2495cc0d2a30'
		  // 
		  // let redirect_uri = encodeURIComponent(url)
		  console.log(`https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${urlx}&response_type=code&scope=snsapi_userinfo&state=STATUS#wechat_redirect`);
		  window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${urlx}&response_type=code&scope=snsapi_userinfo&state=STATUS#wechat_redirect`
		  // 跳转到loginsucess.vue页面，在域名上得到code
	  },
	  signout(){
		  localStorage.removeItem('usermessage')
		  location.reload()
	  },
	  showscondnav(){
		  var that = this
		  this.scondnavshows=true
		  setTimeout(function(){
			  $('.othershow').css('margin-left','0')
		  },100)
	  },
	  closescondnav(){
		  var that = this
		  $('.othershow').css('margin-left','-1000px')
		  setTimeout(function(){
		  		that.scondnavshows=false
		  }, 300)
		  
	  }
  }
}

</script>

<style scoped="scoped">
	@import '../assets/css/alibaba.css';
	/* @media (min-width: 320px){
		
	} */

	.header-wrap{
		display: none;
	}
	.nav-mobilelist{
		display: none;
	}
	/* PC端顶部导航栏 */
	.PCnavtop-wrap{
		width: 100%;
		padding-bottom: 50rpx;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 2;
		
	}
	.PCnavtop-center{
		width: 92%;
		height: 100px;
		margin: 0 auto;
		/* border-bottom: #808082 1px solid; */
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.PCnavtop-centerleft{
		width: 150px;
	}
	.PCnavtop-centerleft img{
		width: 100%;
	}
	.PCnavtop-centermiddle{
		width: 62%;
		height: 32px;
		margin-left: 50px;
		display: flex;
		align-items: center;
	}
	.PCnavtop-navlist1{
		margin-right: 50px;
		line-height: 32px;
		color: #fff;
		font-weight: bold;
		position: relative;
	}
	a:-webkit-any-link {
	    cursor: pointer;
	    text-decoration: none;
	    text-decoration-line: none;
	}
	.router-link-active{
	text-decoration: none;
	}
	.PCnavtop-navlist1:hover{
		cursor: pointer;
	}
	/* 其他导航列表 */
	.PCnavtop-navlist2{
		height: 32px;
		line-height: 32px;
		margin-right: 50px;
		color: #fff;
		font-weight: bold;
		position: relative;
	}
	.PCnavtop-navlist2:hover{
		cursor: pointer;
	}
	.otherstop{
		display: flex;
	}
	.icon-down21{
		color: #fff;
		font-size: 10px;
		margin-left: 5px;
	}
	.otherslist{
		width: 110px;
		background-color: rgba(0,0,0,0.3);
		position: absolute;
		left: -25px;
		top: 32px;
		border-radius: 3px;
		box-shadow:0 0 10px rgba(153, 153,153, 0.5);
		padding: 3px 0;
		display: none;
	}
	.PCnavtop-navlist2:hover .otherslist{
		display: block;
	}
	
	.othersbotlist{
		width: 100%;
		height: 36px;
		line-height: 36px;
		display: block;
		color: #fff;
		font-weight: bold;
	}
	.othersbotlist:hover{
		background-color: rgba(114,111,111,0.6);
	}
	
	
	/* 导航右侧 */
	.PCnavtop-centerright{
		width: 38%;
		display: flex;
		align-items: center;
	}
	.gologin{
		height: 30px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		line-height: 30px;
		border: #FFf 1px solid;
		box-sizing: border-box;
		border-radius: 3px;
		padding: 0 5px;
	}
	.gologin:hover{
		cursor: pointer;
	}
	.gologin div:nth-of-type(2){
		/* margin-left: 5px; */
		color: #fff;
		font-family: '微软雅黑';
		font-size: 14px;
		letter-spacing: 1px;
	}
	.gologin div span{
		color: #fff;
		font-size: 22px;
	}
	.phonenum{
		margin-left: 10px;
		height: 30px;
		display: flex;
		align-items: center;
	}
	.phonenum div{
		margin-left: 10px;
	}
	.phonenum div span{
		color: #fff;
		font-size: 16px;
		letter-spacing: 1px;
	}
	
	/* 手机端样式 */
	@media (min-width: 300px) and (max-width: 500px){
		.PCnavtop-center{
			display: none;
		}
		/* 移动端顶部导航 */
		.header-wrap{
			width: 100%;
			height: 60px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			position: relative;
		}
		.menu-extras{
			width: 60px;
			height: 60px;
			display: flex;
			justify-content: center;
			align-items: center;
			position: absolute;
			left: 0px;
			top: 0;
			z-index: 3;
		}
		.nav-mobile-button{
			background-color: transparent;
			height: 30px;
			border: 1px solid transparent; 
			outline: none;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
		}
		.nav-mobile-button img{
			display: block;
			width: 27px; 
			height: 30px;
		}
		.nav-mobile-button .icon-bar{
			display: block; 
			width: 27px; 
			height: 2.2px; 
			background: white; 
			border-radius: 1px;
		}
		
		.rotate{
			transform-origin:center center; /*旋转中心要是正中间才行*/
			transform: rotate(90deg);
			-webkit-transform: rotate(90deg);
			-moz-transform: rotate(90deg);
			-ms-transform: rotate(90deg);
			-o-transform: rotate(90deg);
			transition: transform 0.2s; 	/*过度时间 可调*/
			-webkit-transition: -webkit-transform 0.2s; 
			-moz-transition: -moz-transform 0.2s; 
			-ms-transition: -ms-transform 0.2s;
			-o-transition: -o-transform 0.2s; 
		}
		.rotate1{
			transform-origin:center center;
			transform: rotate(0deg);		/*返回原点*/
			-webkit-transform: rotate(0deg);
			-moz-transform: rotate(0deg);
			-ms-transform: rotate(0deg);
			-o-transform: rotate(0deg);
			transition: transform 0.2s; 
			-webkit-transition: -webkit-transform 0.2s; 
			-moz-transition: -moz-transform 0.2s; 
			-ms-transition: -ms-transform 0.2s; 
			-o-transition: -o-transform 0.2s; 
		}
		.logo-right{
			width: 100%;
			height: 60px;
			display: flex;
			justify-content: center;
			align-items: center;
			color: white;
			font-weight: bold;
			z-index: 2;
			letter-spacing: 1px;
		}
		.logo-right img{
			width: 90px;
			height: auto;
		}
		.nav-mobilelist{
			display: block;
		}
		.navshow{
			position: absolute;
			top: 60px;
			width: 230px;
			height: calc(100vh - 60px);
			background-color: rgba(0,0,0,0.9);
			z-index: 3;
			margin-left: -1000px;
			transition: 0.4s;
			display: flex;
			flex-direction: column;
		}
		.PCnavtop-navlist1{
			width: 230px;
			margin-top: 25px;
		}
		.PCnavtop-navlist3{
			width: 35%;
			height: 30px;
			margin: 30px auto 0;
			border-radius: 3px;
			display: flex;
			justify-content: center;
			align-items: center;
			line-height: 30px;
			font-size: 14px;
			color: #fff;
			border: #fff 1px solid;
			box-sizing: border-box;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space:nowrap; 
		}
		.PCnavtop-navlist4{
			width: 35%;
			height: 30px;
			margin: 20px auto 0;
			border-radius: 3px;
			display: flex;
			justify-content: center;
			align-items: center;
			line-height: 30px;
			font-size: 14px;
			color: #fff;
			box-sizing: border-box;
			background-color: #007400;
		}
		/* 其他项目 */
		.secondnav{
			position: fixed;
			left: 0;
			top: 0;
			width: 100%;
			height: 100vh;
			z-index: 11;
		}
		.othershow{
			position: absolute;
			top: 60px;
			width: 230px;
			height: calc(100vh - 60px);
			background-color: rgba(80,77,77,0.9);
			z-index: 10;
			margin-left: -1000px;
			transition: 0.6s;
			display: flex;
			flex-direction: column;
		}
		.otherblack{
			position: absolute;
			top: 60px;
			width: 100%;
			height: calc(100vh - 60px);
			/* background-color: #f7f7f7; */
			opacity: 0.7;
			z-index: 2;
			margin-left: 0px;
		}
		
		
		/* 微信登录样式 */
		.PCnavtop-navlist2{
			width: 230px;
			margin-top: 30px;
			display: flex;
			justify-content: center;
		}
		.icon-weixin{
			font-size: 30px;
			color: #007400;
			line-height: 30px;
		}
		.PCnavtop-navlist2 div{
			font-size: 15px;
			color: #fff;
			line-height: 30px;
			margin-left: 5px;
		}
		.navshowblack{
			position: fixed;
			top: 60px;
			width: 100%;
			height: calc(100vh - 60px);
			background-color: #f7f7f7;
			opacity: 0.7;
			z-index: 2;
			margin-left: -1000px;
		}
		.nav-content{
			padding-top: 2rem;
		}
		.nav-content div{
			margin-left: 2rem;
			height: 3rem;
			line-height: 3rem;
			font-weight: bold;
		}
	}
	/* pad端样式 */
	@media (min-width: 500px) and (max-width: 800px){
		.PCnavtop-center{
			display: none;
		}
		/* 移动端顶部导航 */
		.header-wrap{
			width: 100%;
			height: 80px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			position: relative;
/* 			position: fixed;
			top: 0;
			left: 0;
			z-index: 1000;
			background-color: rgba(0,0,0,0.4); */
		}
		.menu-extras{
			width: 60px;
			height: 80px;
			display: flex;
			justify-content: center;
			align-items: center;
			position: absolute;
			left: 0px;
			top: 0;
			z-index: 3;
		}
		.nav-mobile-button{
			background-color: transparent;
			height: 40px;
			border: 1px solid transparent; 
			outline: none;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
		}
		.nav-mobile-button img{
			display: block;
			width: 32px; 
			height: 35px;
			margin-left: 5px;
		}
		.nav-mobile-button .icon-bar{
			display: block; 
			width: 27px; 
			height: 2.2px; 
			background: white; 
			border-radius: 1px;
		}
		
		.rotate{
			transform-origin:center center; /*旋转中心要是正中间才行*/
			transform: rotate(90deg);
			-webkit-transform: rotate(90deg);
			-moz-transform: rotate(90deg);
			-ms-transform: rotate(90deg);
			-o-transform: rotate(90deg);
			transition: transform 0.2s; 	/*过度时间 可调*/
			-webkit-transition: -webkit-transform 0.2s; 
			-moz-transition: -moz-transform 0.2s; 
			-ms-transition: -ms-transform 0.2s;
			-o-transition: -o-transform 0.2s; 
		}
		.rotate1{
			transform-origin:center center;
			transform: rotate(0deg);		/*返回原点*/
			-webkit-transform: rotate(0deg);
			-moz-transform: rotate(0deg);
			-ms-transform: rotate(0deg);
			-o-transform: rotate(0deg);
			transition: transform 0.2s; 
			-webkit-transition: -webkit-transform 0.2s; 
			-moz-transition: -moz-transform 0.2s; 
			-ms-transition: -ms-transform 0.2s; 
			-o-transition: -o-transform 0.2s; 
		}
		.logo-right{
			width: 100%;
			height: 80px;
			display: flex;
			justify-content: center;
			align-items: center;
			color: white;
			font-weight: bold;
			z-index: 2;
			font-size: 18px;
			letter-spacing: 2px;
		}
		.logo-right img{
			width: 120px;
			height: auto;
		}
		.nav-mobilelist{
			display: block;
		}
		.navshow{
			position: absolute;
			top: 80px;
			width: 300px;
			height: calc(100vh - 80px);
			background-color: rgba(0,0,0,0.9);
			z-index: 900 !important;
			margin-left: -1000px;
			transition: 0.4s;
			display: flex;
			flex-direction: column;
		}
		.PCnavtop-navlist1{
			width: 280px;
			margin-top: 40px;
			letter-spacing: 1px;
		}
		.PCnavtop-navlist3{
			width: 40%;
			height: 40px;
			margin: 30px auto 0;
			border-radius: 3px;
			display: flex;
			justify-content: center;
			align-items: center;
			line-height: 30px;
			font-size: 15px;
			color: #fff;
			border: #fff 1px solid;
			box-sizing: border-box;
			letter-spacing: 1px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space:nowrap; 
		}
		.PCnavtop-navlist4{
			width: 40%;
			height: 40px;
			margin: 30px auto 0;
			border-radius: 3px;
			display: flex;
			justify-content: center;
			align-items: center;
			line-height: 30px;
			font-size: 15px;
			color: #fff;
			box-sizing: border-box;
			letter-spacing: 1px;
			background-color: #007400;
		}
		/* 其他项目 */
		.secondnav{
			position: fixed;
			left: 0;
			top: 0;
			width: 100%;
			height: 100vh;
			z-index: 901;
		}
		.othershow{
			position: absolute;
			top: 80px;
			width: 300px;
			height: calc(100vh - 80px);
			background-color: rgba(80,77,77,0.9);
			z-index: 10;
			margin-left: -1000px;
			transition: 0.6s;
			display: flex;
			flex-direction: column;
		}
		.otherblack{
			position: absolute;
			top: 80px;
			width: 100%;
			height: calc(100vh - 80px);
			/* background-color: #f7f7f7; */
			opacity: 0.7;
			z-index: 2;
			margin-left: 0px;
		}
		
		
		/* 微信登录样式 */
		.PCnavtop-navlist2{
			width: 280px;
			margin-top: 30px;
			display: flex;
			justify-content: center;
		}
		.icon-weixin{
			font-size: 30px;
			color: #007400;
			line-height: 30px;
		}
		.PCnavtop-navlist2 div{
			font-size: 16px;
			color: #fff;
			line-height: 30px;
			margin-left: 5px;
		}
		.navshowblack{
			position: fixed;
			top: 80px;
			width: 100%;
			height: calc(100vh - 60px);
			background-color: #f7f7f7;
			opacity: 0.7;
			z-index: 2;
			margin-left: -1000px;
		}
		.nav-content{
			padding-top: 2rem;
		}
		.nav-content div{
			margin-left: 2rem;
			height: 3rem;
			line-height: 3rem;
			font-weight: bold;
		}
	}
	/* 大pad端 */
	@media (min-width: 801px) and (max-width: 1024px){
		
		
	}
	
	
	/* 老板仓定制机的样式 */
	@media (min-width: 1025px) and (max-width: 1300px){
	/* @media (min-width: 0px) and (max-width: 0px){ */
		.header-wrap{
			display: none;
		}
		.nav-mobilelist{
			display: none;
		}
		/* PC端顶部导航栏 */
		.PCnavtop-wrap{
			width: 100%;
			padding-bottom: 50px;
			position: absolute;
			left: 0;
			top: 0;
			z-index: 2;
		}
		.PCnavtop-center{
			width: 97%;
			height: 100px;
			margin: 0 auto;
			/* border-bottom: #808082 1px solid; */
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		.PCnavtop-centerleft{
			width: 110px;
		}
		.PCnavtop-centerleft img{
			width: 100%;
		}
		.PCnavtop-centermiddle{
			width: 60%;
			margin-left: 25px;
			display: flex;
			align-items: center;
		}
		.PCnavtop-navlist1{
			margin-right: 30px;
			font-size: 0.98rem;
			color: #fff;
			font-weight: bold;
		}
		a:-webkit-any-link {
		    cursor: pointer;
		    text-decoration: none;
		    text-decoration-line: none;
		}
		.router-link-active{
		text-decoration: none;
		}
		.PCnavtop-navlist1:hover{
			cursor: pointer;
		}
		/* 导航右侧 */
		.PCnavtop-centerright{
			width: 38%;
			display: flex;
			align-items: center;
		}
		.gologin{
			height: 25px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			line-height: 30px;
			border: #FFf 1px solid;
			box-sizing: border-box;
			border-radius: 3px;
			padding: 0 5px;
		}
		.gologin:hover{
			cursor: pointer;
		}
		.gologin div:nth-of-type(1) span{
			font-size: 14px;
		}
		.gologin div:nth-of-type(2){
			margin-left: 5px;
			color: #fff;
			font-family: '微软雅黑';
			font-size: 11px;
			letter-spacing: 1px;
		}
		.phonenum{
			margin-left: 5px;
			height: 25px;
			display: flex;
			align-items: center;
		}
		.phonenum div{
			margin-left: 10px;
		}
		.phonenum div span{
			color: #fff;
			font-size: 12px;
			letter-spacing: 1px;
		}
	}
	
</style>
