<template>
  <div id="app">
	  <!-- 头部导航组件 -->
	  <!-- <Headernew/> -->
	  <!-- 要加载的页面组件 -->
	  <router-view></router-view>
	  <!-- 底部 -->
	  <!-- <Footernew/> -->
  </div>
</template>

<script>
	import Headernew from '@/components/Headernew.vue'
	import Footernew from '@/components/Footernew.vue'

	export default {
	  name: 'Home',
	  components: {
	    Headernew,
		Footernew
	  }
	}
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

body{
	padding: 0;
	margin: 0;
}
</style>
