import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
	  count: 0,//分镜确认单 移动端缩放比例
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
